import React from 'react';
import { Container, Box, Grid, Stack, Typography } from '@mui/material';
import skin from '@assets/spaCover/overview/icons/skin.png';
import zipper from '@assets/spaCover/overview/icons/zipper.png';
import hinge from '@assets/spaCover/overview/icons/hinge.png';
import scissors from '@assets/spaCover/overview/icons/scissors.png';
import handles from '@assets/spaCover/overview/icons/handles.png';
import dumbbell from '@assets/spaCover/overview/icons/dumbbell.png';
import waterOff from '@assets/spaCover/overview/icons/water-off.png';
import lock from '@assets/spaCover/overview/icons/lock.png';
import weather from '@assets/spaCover/overview/icons/weather-snowy-rainy.png';
import FeatureItem from '@components/SpaCover/Overview/FeatureItem';
const FeaturesSection: React.FunctionComponent = () => {
    return (
        <Box width="100%" bgcolor="rgba(33,150,243,0.1)" p={8}>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <FeatureItem
                                    img={skin}
                                    title="Vinyl Skin"
                                    description="Top quality marine grade vinyl, which is UV treated to resist fading, cracking
                                        and mildew."
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FeatureItem
                                    img={zipper}
                                    title="Nylon zipper"
                                    description="Heavy-duty zipper is expertly sewn in, allowing for easy access to foam insert."
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FeatureItem
                                    img={hinge}
                                    title="Hinge Insulation"
                                    description="This optional upgrade helps to effectively seal the hinge to prevent the loss of
                                        heat and steam from your hot tub when the cover is on."
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FeatureItem
                                    img={scissors}
                                    title="Skirt"
                                    description='The skirt is meticulously sewn from TWO layers of vinyl, and comes in a standard
                                        length of 2.5". Can be made longer if needed or we can make with no skirt.'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FeatureItem
                                    img={handles}
                                    title="Handles"
                                    description="Reinforced cross-stitched handles helps to lift covers easily."
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <FeatureItem
                                    img={dumbbell}
                                    title="Reinforced C-Channel"
                                    description="Metal c-channels run along the base of our foam inserts to offer support from
                                        loads like rain and snow. These channels are made of galvanized steel to prevent
                                        rust and warping from extreme temperatures."
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FeatureItem
                                    img={waterOff}
                                    title="Foam insert"
                                    description="All inserts are vacuum-heat sealed in 3-mil poly for extra protection against
                                        moisture."
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FeatureItem
                                    img={lock}
                                    title="Locking Strap"
                                    description="Lock-down straps that clip in place to prevent from wind blowing your hot tub
                                        cover off and plastic locks with keys give added security from children getting
                                        into your spa."
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FeatureItem
                                    img={weather}
                                    title="Tapering"
                                    description="Tapered hot tub covers are recommended for outdoors to help with snow and rain
                                        flow-off while flat covers suite best for indoors."
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
export default FeaturesSection;
