import React, { useEffect, useState } from 'react';
import { AppBar, Box, Container, Toolbar, Grid, Link, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from '@assets/logos/logo.png';
import PersonIcon from '@mui/icons-material/Person';
import TextButton from '@components/Utilities/Buttons/TextButton';

const theme = createTheme();

const useStyles = makeStyles(() => ({
    toolbar: {
        backgroundColor: 'white'
    },
    toolbarBig: {
        height: 120,
        transition: 'height .5s'
    },
    toolbarSmall: {
        height: 80,
        transition: 'height .5s'
    },
    logoBig: {
        height: 70,
        transition: 'height .5s'
    },
    logoSmall: {
        height: 50,
        transition: 'height .5s'
    },
    sectionDesktop: {
        display: 'none !important',
        [theme.breakpoints.up('md')]: {
            display: 'flex !important'
        }
    },
    sectionMobile: {
        display: 'flex !important',
        [theme.breakpoints.up('md')]: {
            display: 'none !important'
        }
    }
}));

interface NavbarProps {
    handleDrawerToggle: () => void;
}

export const FixNavbarSpace = () => {
    const classes = useStyles();
    const location = useLocation();
    const showExtraAppBar = () => {
        return location.pathname === '/spaCoverOverview' || location.pathname === '/safetyCoverOverview';
    };

    return (
        <Box>
            <Toolbar className={classes.toolbarBig} />
            {showExtraAppBar() && <Toolbar />}
        </Box>
    );
};

const Navbar: React.FunctionComponent<NavbarProps> = (props) => {
    const { handleDrawerToggle } = props;
    const classes = useStyles();
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const [show, setShow] = useState<boolean>(true);
    const navigate = useNavigate();
    const location = useLocation();

    const getElevation = () => {
        if (scrollPosition > 10) {
            return 8;
        }
        return 0;
    };
    const showExtraAppBar = () => {
        return location.pathname.includes('spaCover') || location.pathname.includes('safetyCover');
    };
    const showWarrantyNavBar = () => {
        return location.pathname.includes('warrantyRegistration');
    };
    const showWarrantyClaimNavBar = () => {
        return location.pathname.includes('warrantyClaim');
    };
    const getTitle = () => {
        if (location.pathname.includes('spaCover')) {
            window.localStorage.setItem("coverType",'Spa Cover');
            return 'Spa Cover';
        }
        window.localStorage.setItem("coverType",'Safety Cover');
        return 'Safety Cover';
    };

    const getOverviewLink = () => {
        if (location.pathname.includes('spaCover')) {
            return '/spaCoverOverview';
        }
        return '/safetyCoverOverview';
    };

    const getTechSpecsLink = () => {
        if (location.pathname.includes('spaCover')) {
            return '/spaCoverTechSpecs';
        }
        return '/safetyCoverTechSpecs';
    };

    useEffect(() => {
        if (scrollPosition > 250) {
            setShow(false);
        } else {
            setShow(true);
        }
    }, [scrollPosition]);

    useEffect(() => {
        window.addEventListener('scroll', () => setScrollPosition(window.pageYOffset), { passive: true });
        return () => {
            window.removeEventListener('scroll', () => null);
        };
    }, []);

    return (
        <AppBar position="fixed" elevation={getElevation()}>
            <Toolbar
                disableGutters
                className={`${classes.toolbar} ${show ? classes.toolbarBig : classes.toolbarSmall}`}
            >
                <Container>
                    <Grid container alignItems="center" mt={1}>
                        <Grid item xs={3}>
                            <Box component={NavLink} to="/">
                                <Box
                                    component="img"
                                    src={logo}
                                    alt="logo"
                                    className={show ? classes.logoBig : classes.logoSmall}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={9} className={classes.sectionDesktop}>
                            <Grid container direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                                <Grid item>
                                    <TextButton variant="body1" to="/">
                                        Home
                                    </TextButton>
                                </Grid>
                                <Grid item>
                                    <TextButton variant="body1" to="/#about">
                                        About
                                    </TextButton>
                                </Grid>
                                <Grid item>
                                    <TextButton variant="body1" to="/#contact">
                                        Contact Us
                                    </TextButton>
                                </Grid>
                                <Grid item>
                                    <TextButton variant="body1" to="/spaCoverOverview">
                                        Spa Cover
                                    </TextButton>
                                </Grid>
                                <Grid item>
                                    <TextButton variant="body1" to="/safetyCoverOverview">
                                        Safety Cover
                                    </TextButton>
                                </Grid>
                                <Grid item>
                                    <IconButton href="https://www.pspfabstore.com/login" target="_black">
                                        <PersonIcon color="primary" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={9} className={classes.sectionMobile}>
                            <Grid container alignItems="center" justifyContent="flex-end">
                                <Grid item>
                                    <IconButton onClick={handleDrawerToggle}>
                                        <MenuIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Toolbar>
            {showExtraAppBar() && (
                 <Toolbar disableGutters className={classes.toolbar}>
                 <Container>
                     <Grid container alignItems="center">
                         <Grid item xs={12} md={2} my={2}>
                             <Typography variant="h5" color="black">
                                 {getTitle()}
                             </Typography>
                         </Grid>
                         <Grid 
                        item 
                        xs={10} 
                        md={10} 
                        sx={{ 
                            display: 'flex', 
                            justifyContent: { xs: 'center', md: 'flex-end' },
                            mx: 'auto' // Para centrar la columna con un margen en xs
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            sx={{
                                justifyContent: { xs: 'center', md: 'flex-end' },
                                mb: { xs: 2, md: 0 }
                            }}
                            spacing={2}
                        >
                                 <Grid item>
                                     <TextButton variant="body1" to={getOverviewLink()}>
                                         Overview
                                     </TextButton>
                                 </Grid>
                                 <Grid item>
                                     <TextButton variant="body1" to={getTechSpecsLink()}>
                                         Tech Specs
                                     </TextButton>
                                 </Grid>
                                 <Grid item>
                                     <NavLink to="/warrantyClaim" style={{ textDecoration: 'none' }}>
                                         <Button variant="contained" color="primary">
                                             Warranty Claim
                                         </Button>
                                     </NavLink>
                                 </Grid>
                                 <Grid item>
                                     <NavLink to="/warrantyRegistrationForm" style={{ textDecoration: 'none' }}>
                                         <Button variant="contained" color="primary">
                                             Warranty Registration
                                         </Button>
                                     </NavLink>
                                 </Grid>
                             </Grid>
                         </Grid>
                     </Grid>
                 </Container>
             </Toolbar>
            )}
            {showWarrantyNavBar() && (
                <Toolbar disableGutters className={classes.toolbar}>
                    <Container>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={6} my={2}>
                                <Typography variant="h5" color="black">
                                    Warranty Registration Form
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    sx={{
                                        justifyContent: { xs: 'center', md: 'flex-end' },
                                        mb: { xs: 2, md: 0 }
                                    }}
                                    spacing={2}
                                >
                                    <Grid item>
                                        <Button variant="text" onClick={() => navigate(-1)}>
                                            Go Back
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            form="warranty-registration-form"
                                        >
                                            SUBMIT
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Toolbar>
            )}
            {showWarrantyClaimNavBar() && (
                <Toolbar disableGutters className={classes.toolbar}>
                    <Container>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={6} my={2}>
                                <Typography variant="h5" color="black">
                                    Warranty Claim Form
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    sx={{
                                        justifyContent: { xs: 'center', md: 'flex-end' },
                                        mb: { xs: 2, md: 0 }
                                    }}
                                    spacing={2}
                                >
                                    <Grid item>
                                        <Button variant="text" onClick={() => navigate(-1)}>
                                            Go Back
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            form="warranty-claim-form"
                                        >
                                            SUBMIT
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Toolbar>
            )}
        </AppBar>
    );
};

export default Navbar;
