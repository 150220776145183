import store from '@store/index';
import { setAlert } from '@store/appStore';

export const showAlertError = (error: any) => {
    if (error.reason) {
        store.dispatch(
            setAlert({
                message: error.reason,
                severity: 'error',
                open: true
            })
        );
        return;
    }
    if (error.message) {
        store.dispatch(
            setAlert({
                message: error.message,
                severity: 'error',
                open: true
            })
        );
        return;
    }
    store.dispatch(
        setAlert({
            message: error,
            severity: 'error',
            open: true
        })
    );
};
