import React from 'react';
import Home from '@screens/Home';
import SpaCoverOverview from 'src/ui/screens/SpaCover/SpaCoverOverview';
import SafetyCoverOverview from '@screens/SafetyCover/SafetyCoverOverview';
import SpaCoverTechSpecs from '@screens/SpaCover/SpaCoverTechSpecs';
import SafetyCoverTechSpecs from '@screens/SafetyCover/SaferyCoverTechSpecs';
import WarrantyRegistrationForm from '@screens/WarrantyRegistrationForm';
import WarrantyClaim from '../ui/components/WarrantyClaim/WarrantyClaim';

export interface RoutesInterface {
    path: string;
    name: string;
    element: React.FunctionComponent;
}

const appRoutes: RoutesInterface[] = [
    {
        path: '/',
        name: 'Home',
        element: Home
    },
    {
        path: '/spaCoverOverview',
        name: 'Spa Cover',
        element: SpaCoverOverview
    },
    {
        path: '/spaCoverTechSpecs',
        name: 'Spa Cover Tech Specs',
        element: SpaCoverTechSpecs
    },
    {
        path: '/safetyCoverOverview',
        name: 'Safety Cover',
        element: SafetyCoverOverview
    },
    {
        path: '/safetyCoverTechSpecs',
        name: 'Safety Cover Tech Specs',
        element: SafetyCoverTechSpecs
    },
    {
        path: '/warrantyRegistrationForm',
        name: 'Warranty Registration Form',
        element: WarrantyRegistrationForm
    },
    {
        path: '/WarrantyClaim',
        name: 'Warranty Claim',
        element: WarrantyClaim
    }
];

export default appRoutes;
