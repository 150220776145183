import React from 'react';
import { Container, Box, Grid, Typography, Stack, Divider } from '@mui/material';
import measuring1 from '@assets/spaCover/techSpecs/measuring/measuring1.png';
import measuring2 from '@assets/spaCover/techSpecs/measuring/measuring2.png';
import measuring3 from '@assets/spaCover/techSpecs/measuring/measuring3.png';
import measuring4 from '@assets/spaCover/techSpecs/measuring/measuring4.png';

const MeasuringSection: React.FunctionComponent = () => {
    return (
        <Container>
            <Grid container>
                <Grid item xs={12} mb={4}>
                    <Divider style={{ width: '100%', background: 'lightgrey', borderBottomWidth: 3 }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4">Measuring Radius Corners</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Stack spacing={2}>
                        <Box component="img" src={measuring1} width={450} />
                        <Typography variant="subtitle2" fontWeight={600}>
                            Method 1
                        </Typography>
                        <Typography variant="body1">
                            Some covers have rounded corners. To measure the radius of these curves extend two imaginary
                            lines to meet at a point from the start of the curve. The length of this imaginary line (red
                            line) represents the radius of the curve. The lines start where the cover begins to curve,
                            and ends where they cross.
                        </Typography>
                        <Typography variant="subtitle2" fontWeight={600}>
                            Method 2
                        </Typography>
                        <Typography variant="body1">
                            If you have an old cover, put the cover on the top of a newspaper and align the two edges of
                            the cover with the edge of newspaper. The distance from the corner of the newspaper, to
                            where the cover completely covers the paper is the radius of the corner.
                        </Typography>
                        <Typography variant="subtitle2" fontWeight={600}>
                            Method 3
                        </Typography>
                        <Typography variant="body1">
                            Trace the outside edge of the rounded corner on a piece of paper. Then FAX it to us at
                            705-797-1380.
                        </Typography>
                        <Typography variant="body1">
                            Note: Measure all radius corners. Make sure that all the radii are equal. A slight
                            difference in each radius will hang the spa or will not cover the spa completely.
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} my={4}>
                    <Divider style={{ width: '100%', background: 'lightgrey', borderBottomWidth: 3 }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4">Measuring Cut Corners</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Stack spacing={2}>
                        <Box component="img" src={measuring2} width={300} />
                        <Typography variant="body1">
                            Some covers have cut-corners as shown in the diagram above. You can simply measure the cut
                            corner using a tape provided the two ends of the cut corner are not rounded.
                        </Typography>
                        <Typography variant="body1">
                            It is difficult to measure a cut-corner when the corner is also rounded. In order to do
                            this, use masking tape, to extend the lines of your spa (or existing cover) as shown below.
                            The line where the tape crosses is measure of the cut corner. In the diagram below, “A” is
                            the measurement of the cut-corner.
                        </Typography>
                        <Box component="img" src={measuring3} width={300} />
                        <Typography variant="body1" fontWeight={600}>
                            Note
                        </Typography>
                        <Typography variant="body1">
                            Be sure to measure all corners as some covers have different cuts.
                        </Typography>
                        <Typography variant="body1">
                            <Typography variant="caption" color="error" fontSize={18}>
                                IMPORTANT!
                            </Typography>{' '}
                            A smaller cut-corner will cause the cover to slightly over hang the spa while a larger cut
                            will not cover the spa completely.
                        </Typography>
                        <Box component="img" src={measuring4} width={300} />
                        <Typography variant="body1">
                            When measuring an octagon spa, it is important to measure all sides of the tub. If all sides
                            are equal, they are regular octagon, if not they are stretch octagons (rectangles with 4 cut
                            corners). For a stretch octagon measure the total height and width as they may not be equal.
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
};
export default MeasuringSection;
