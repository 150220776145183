import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import {getFirestore, collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';


const firebaseConfig = {
    apiKey: 'AIzaSyBxYWhTOQMLF3GxE-swXlOcb3HDIQ4OU68',
    authDomain: 'spa-cover-system.firebaseapp.com',
    projectId: 'spa-cover-system',
    storageBucket: 'spa-cover-system.appspot.com',
    messagingSenderId: '104690825011',
    appId: '1:104690825011:web:6d99e23773a5c1ab947046',
    measurementId: 'G-09ZN6EFZZC'
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);

const db = getFirestore(app);
export { db, collection, addDoc, getDocs, updateDoc, deleteDoc, doc };

