import React from 'react';
import { Container, Box, Grid, Stack, Typography, Avatar, Fab } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import PlaceIcon from '@mui/icons-material/Place';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import PeopleIcon from '@mui/icons-material/People';
import { Circle } from '@mui/icons-material';
import CircleIcon from '@components/Utilities/Icons/CircleIcon';
const OurFacilitiesSection: React.FunctionComponent = () => {
    return (
        <Box width="100%" py={4}>
            <Container >
                <Grid container spacing={8}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h3" textAlign="center" >
                            Our facilities
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6} md={3}>
                                <Stack alignItems="center" spacing={2}>
                                    <CircleIcon Icon={StarIcon} />
                                    <Typography variant="h5" textAlign="center" height={70}>
                                        Excellent Customer Service
                                    </Typography>
                                    <Typography variant="body1" textAlign="center">
                                        Our team is dedicated to attending all of your queries and concerns while
                                        providing top-tier assistance with professionalism. We pride ourselves on an
                                        in-depth knowledge of our products and can give you a definitive answer about
                                        how they work, what they're made of and what the solution is best for you.
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Stack alignItems="center" spacing={2}>
                                    <CircleIcon Icon={PlaceIcon} />
                                    <Typography variant="h5" textAlign="center" height={70}>
                                        Fast Delivery
                                    </Typography>
                                    <Typography variant="body1" textAlign="center">
                                        PSP Fabricating INC. is located in a 28,000 square foot warehouse in Barrie,
                                        Ontario. We are strategically situated and have access to all major
                                        transportation routes. We work with several logistics providers to ensure your
                                        product arrives at your doorstep on time and in one piece.
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Stack alignItems="center" spacing={2}>
                                    <CircleIcon Icon={RocketLaunchIcon} />
                                    <Typography variant="h5" textAlign="center" height={70}>
                                        Constant Modernization
                                    </Typography>
                                    <Typography variant="body1" textAlign="center">
                                        We continuously invest in our own R&D to stay one step ahead of our competition
                                        for new product development, techniques, and adaptation. If we find a
                                        cost-effective way to improve our craftsmanship then we invest, reorganize, and
                                        implement updates without disrupting our production line.
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Stack alignItems="center" spacing={2}>
                                    <CircleIcon Icon={PeopleIcon} />
                                    <Typography variant="h5" textAlign="center" height={70}>
                                        Experienced Staff
                                    </Typography>
                                    <Typography variant="body1" textAlign="center">
                                        Our diverse workforce consists of loyal and dedicated staff, including those who
                                        have been with us for over 20 years. Our work environment places an emphasis on
                                        problem solving and team building which prepares our workers to meet any
                                        challenge head-on.
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
export default OurFacilitiesSection;
