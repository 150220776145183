import React from 'react';
import { Container, Divider, Grid, Typography } from '@mui/material';
const FeaturesSection: React.FunctionComponent = () => {
    return (
        <Container>
            <Grid container>
                <Grid item xs={12} mb={4}>
                    <Divider style={{ width: '100%', background: 'lightgrey', borderBottomWidth: 3 }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4">Features</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Skin
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">
                                We use the best quality marine grade vinyl, which is UV treated to resist fading,
                                cracking and mildew.
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Aluminum reinforcement channel
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">
                                We use the best quality marine grade vinyl, which is UV treated to resist fading,
                                cracking and mildew.
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Nylon Zipper
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">
                                Heavy-duty zipper is expertly sewn in, allowing for easy access to foam insert.
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Form insert
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">
                                All inserts are vacuum-heat sealed in 4-mil poly for extra protection against
                                moisture’s.
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Super reinforced hinge seal
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">
                                The double reinforced hinge on all our covers helps seal effectively to avoid moisture
                                loss and conserve energy.
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Locking Strap
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">
                                Lock-down straps that clip in place to prevent from wind blowing your hot tub cover off
                                and plastic locks with keys give added security from children getting into your spa.
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Skirt
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">
                                The skirt is meticulously sewn from TWO layers of vinyl, and comes in a standard length
                                of 2.5″. Can be made longer if needed or we can make with no skirt.
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Tapering
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">
                                Tapered hot tub covers are recommended for outdoors to help with snow and rain flow-off
                                while flat covers suite best for indoors.
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Handles
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">
                                Reinforced cross-stitched handles helps to lift covers easily.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};
export default FeaturesSection;
