import { AlertColor } from '@mui/material';

export interface AlertType {
    message: string;
    severity: AlertColor;
    open: boolean;
}

export interface AppState {
    isLoading: boolean;
    loaderMessage: string;
    progressBar?: number;
    alert: AlertType;
    darkMode: boolean;
}

enum AppActionsTypes {
    SetIsLoading = 'SET_IS_LOADING',
    SetLoaderMessage = 'SET_LOADER_MESSAGE',
    SetProgressBar = 'SET_PROGRESS_BAR',
    SetAlert = 'SET_ALERT',
    SetDarkMode = 'SET_DARK_MODE'
}

interface AppActions {
    type?: AppActionsTypes;
    payload?: any;
}

const initialState: AppState = {
    alert: {
        message: 'Bongless Admin',
        open: false,
        severity: 'success'
    },
    isLoading: false,
    loaderMessage: 'Loading',
    darkMode: false
};

const appReducer = (state: AppState = initialState, action: AppActions = {}) => {
    switch (action.type) {
        case AppActionsTypes.SetIsLoading:
            return {
                ...state,
                isLoading: action.payload,
                loaderMessage: initialState.loaderMessage
            };
        case AppActionsTypes.SetLoaderMessage:
            return {
                ...state,
                loaderMessage: action.payload
            };
        case AppActionsTypes.SetProgressBar:
            return {
                ...state,
                progressBar: action.payload
            };
        case AppActionsTypes.SetAlert:
            return {
                ...state,
                alert: action.payload
            };
        case AppActionsTypes.SetDarkMode:
            return {
                ...state,
                darkMode: action.payload
            };
        default:
            return state;
    }
};

export const setIsLoading = (loadingStatus: boolean) => {
    return { payload: loadingStatus, type: AppActionsTypes.SetIsLoading };
};

export const setLoaderMessage = (message: string) => {
    return { payload: message, type: AppActionsTypes.SetLoaderMessage };
};

export const setProgressBar = (progressBar?: number) => {
    return { payload: progressBar, type: AppActionsTypes.SetProgressBar };
};

export const setAlert = (alert: AlertType) => {
    return { payload: alert, type: AppActionsTypes.SetAlert };
};

export const setDarkMode = (mode: boolean) => {
    return { payload: mode, type: AppActionsTypes.SetDarkMode };
};

export default appReducer;
