import React from 'react';
import { Stack } from '@mui/material';
import SafetyCoversSection from 'src/ui/components/SafetyCover/Overview/SafetyCoversSection';
import WarrantySection from 'src/ui/components/SafetyCover/Overview/WarrantySection';
import HelpsSection from 'src/ui/components/SafetyCover/Overview/HelpsSection';
import FreedomSection from 'src/ui/components/SafetyCover/Overview/FreedomSection';
import FeaturesSection from 'src/ui/components/SafetyCover/Overview/FeaturesSection';
import HardwareSection from 'src/ui/components/SafetyCover/Overview/HardwareSection';

const SafetyCoverOverview: React.FunctionComponent = () => {
    return (
        <Stack minHeight="100vh" alignItems="center" spacing={8}>
            <SafetyCoversSection />
            <WarrantySection />
            <HelpsSection />
            <FreedomSection />
            <FeaturesSection />
            <HardwareSection />
        </Stack>
    );
};
export default SafetyCoverOverview;
