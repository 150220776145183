import * as Yup from 'yup';
import React, { useMemo } from 'react';
import { Container, Grid, Stack, Typography, Button, TextField, Box } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Contact } from '@api/Contacts/Contact';
import { hideLoader, showLoader } from '@utils/Loader';
import { showAlertMessage } from '@utils/Alerts/Message';
import { showAlertError } from '@utils/Alerts/Error';
import ContactService from '@api/Contacts/ContactCtrl';

const contactSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required'),
    message: Yup.string().required('Message is required')
});
const ContactSection: React.FunctionComponent = () => {
    const contactService = useMemo(() => new ContactService(), []);

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<Contact>({
        resolver: yupResolver(contactSchema)
    });

    const onContact: SubmitHandler<Contact> = async (values) => {
        showLoader();
        try {
            await contactService.sentMessage(values);
            reset();
            showAlertMessage('Contact data sent successfully');
        } catch (error: any) {
            showAlertError(error);
        }
        hideLoader();
    };

    return (
        <Box width="100%" bgcolor="white" py={10}>
            <Container>
                <form id="contact-form" onSubmit={handleSubmit(onContact)}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} md={6} id="#about">
                            <Stack alignItems="center" spacing={2}>
                                <Typography variant="h3" textAlign="center">
                                    About us
                                </Typography>
                                <Typography variant="body1" textAlign="justify">
                                    Starting as a small business in 1998, PSP Fabricating Inc., rapidly grew into a
                                    leading manufacturer of safety covers and hot tub covers. Our
                                    diverse group of industrious and efficient workers dedicate themselves to making
                                    high quality products that meet the needs and demands of our valued customers.
                                </Typography>
                                <Typography variant="body1" textAlign="justify">
                                    In keeping pace with technology, we have invested in state-of-the-art cost-efficient
                                    equipment and techniques. With our own internal R&D division we leverage our
                                    knowledge to stay on top of today's constantly changing technological environment
                                    for innovation in our industry.
                                </Typography>
                                <Typography variant="body1" textAlign="justify">
                                    Our customer base extends throughout all of Canada's provinces and our products have
                                    made their way into international markets such as the Americas and Europe.
                                </Typography>
                                <Typography variant="body1" textAlign="justify">
                                    We pride ourselves in providing top-notch personalized customer service. We are
                                    dependable, friendly and easily available to you. We are fully computerized and most
                                    orders are fulfilled within 10 business days.
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} id="#contact">
                            <Stack alignItems="center" spacing={2}>
                                <Typography variant="h3" textAlign="center">
                                    Contact Us
                                </Typography>
                                <Typography variant="h5" textAlign="center">
                                    We are here for you!
                                </Typography>
                                <Typography variant="body1" textAlign="justify">
                                    If you need technical assistance in measuring and installing covers or any other
                                    related questions, please feel free to contact us.
                                </Typography>
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            fullWidth
                                            required
                                            label="Name"
                                            variant="standard"
                                            autoComplete="name"
                                            value={value}
                                            onChange={onChange}
                                            error={!!errors.name}
                                            helperText={errors.name && errors.name.message}
                                        />
                                    )}
                                />
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            fullWidth
                                            required
                                            label="E-mail Address"
                                            variant="standard"
                                            autoComplete="email"
                                            type="email"
                                            value={value}
                                            onChange={onChange}
                                            error={!!errors.email}
                                            helperText={errors.email && errors.email.message}
                                        />
                                    )}
                                />
                                <Controller
                                    name="message"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            fullWidth
                                            required
                                            multiline
                                            rows={5}
                                            label="Message"
                                            variant="standard"
                                            autoComplete="message"
                                            value={value}
                                            onChange={onChange}
                                            error={!!errors.message}
                                            helperText={errors.message && errors.message.message}
                                        />
                                    )}
                                />
                                <Button
                                    fullWidth
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    form="contact-form"
                                    size="large"
                                >
                                    SUBMIT MESSAGE
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </Box>
    );
};
export default ContactSection;
