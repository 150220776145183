import { MenuItem, Select, SelectProps, Typography } from '@mui/material';
import React from 'react';

const ProductSelect: React.FunctionComponent<SelectProps> = (props) => {
    return (
        <Select
            variant="standard"
            defaultValue="Spa Cover"
            renderValue={(selected: any) => <Typography>{selected}</Typography>}
            {...props}
        >
            <MenuItem value="Spa Cover">Spa Cover</MenuItem>
            <MenuItem value="Pool Safety Cover">Pool Safety Cover</MenuItem>
            
        </Select>
    );
};

export default ProductSelect;
