import React from 'react';
import { Container, Box, Grid, Stack, Typography } from '@mui/material';
import astm from '@assets/spaCover/overview/astm.png';
import warranty from '@assets/safetyCover/overview/20warranty.png';
import shape from '@assets/safetyCover/overview/shape.png';
import render from '@assets/safetyCover/overview/render.png';
const WarrantySection: React.FunctionComponent = () => {
    return (
        <Container>
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} md={7}>
                            <Stack
                                borderTop={1}
                                borderRight={1}
                                borderBottom={1}
                                borderLeft={1}
                                borderRadius={5}
                                borderColor="gray"
                                height="100%"
                                justifyContent="center"
                                alignItems="center"
                                spacing={4}
                                p={4}
                            >
                                <Typography variant="h5" textAlign="center">
                                    Meets or exceeds all ASTM standards
                                </Typography>
                                <Box component="img" src={astm} width="60%" />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box
                                display="flex"
                                borderTop={1}
                                borderRight={1}
                                borderBottom={1}
                                borderLeft={1}
                                borderRadius={5}
                                borderColor="gray"
                                height="100%"
                                justifyContent="center"
                                alignItems="center"
                                p={4}
                            >
                                <Box component="img" src={warranty} width="100%" />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} md={5}>
                            <Stack
                                borderTop={1}
                                borderRight={1}
                                borderBottom={1}
                                borderLeft={1}
                                borderRadius={5}
                                borderColor="gray"
                                height="100%"
                                justifyContent="center"
                                alignItems="center"
                                spacing={4}
                                p={4}
                            >
                                <Box component="img" src={shape} width={250} />
                                <Typography variant="body1" textAlign="center">
                                    Accurately measured, precisely designed, and perfectly built to fit your pool
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Stack
                                borderTop={1}
                                borderRight={1}
                                borderBottom={1}
                                borderLeft={1}
                                borderRadius={5}
                                borderColor="gray"
                                height="100%"
                                justifyContent="center"
                                alignItems="center"
                                spacing={4}
                                p={4}
                            >
                                <Typography variant="body1" textAlign="center">
                                    Only the highest quality materials and hardware are used on our safety covers
                                </Typography>
                                <Box component="img" src={render} width={350} />
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};
export default WarrantySection;
