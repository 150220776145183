import React from 'react';
import { Container, Box, Grid, Stack, Typography } from '@mui/material';
import drowning from '@assets/safetyCover/overview/icons/drowning.png';
import thunder from '@assets/safetyCover/overview/icons/thunder.png';
import shield from '@assets/safetyCover/overview/icons/shield.png';
import shapes from '@assets/safetyCover/overview/icons/shapes.png';
import weather from '@assets/spaCover/overview/icons/weather-snowy-rainy.png';
import FeatureItem from '@components/SpaCover/Overview/FeatureItem';
const FeaturesSection: React.FunctionComponent = () => {
    return (
        <Box width="100%" bgcolor="rgba(33,150,243,0.1)" p={8}>
            <Container>
                <Grid container spacing={8}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <FeatureItem
                                    img={drowning}
                                    title="Prevents Drowning"
                                    description=" According to Canada Safety Council nearly 500 Canadians die every year in
                                        water-related incidents. Drowning is the second leading cause of preventable
                                        death for children under 10 years of age, while children under the age of five
                                        are most at risk. Six per cent of all drowning deaths occurred in private pools.
                                        Hence the prime benefit of a safety cover over one’s swimming pool is that it
                                        prevents drowning."
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FeatureItem
                                    img={thunder}
                                    title="Saves Times"
                                    description="A swimming pool safety cover can be put on and taken off in under 20 minutes."
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <FeatureItem
                                    img={shield}
                                    title="Protects your Investment"
                                    description="Owning and maintaining a swimming pool is an investment for years of enjoyment
                                        and pleasure be it just for recreation or health. Protecting your investment
                                        becomes of paramount importance!. A safety cover over your swimming pool provide
                                        exceptional protection from the elements during winter, keeping your pool in top
                                        condition and making easy opening each spring."
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FeatureItem
                                    img={shapes}
                                    title="Aesthetically Pleasing"
                                    description="Every swimming pool safety cover is custom made to its shape and size thus
                                        making it look just like the shape of the pool. When stretched over the pool it
                                        is eye pleasing!"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
export default FeaturesSection;
