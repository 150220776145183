import React from 'react';
import { Container, Divider, Grid, Typography } from '@mui/material';
const InstallationStepsSection: React.FunctionComponent = () => {
    return (
        <Container>
            <Grid container>
                <Grid item xs={12} mb={4}>
                    <Divider style={{ width: '100%', background: 'lightgrey', borderBottomWidth: 3 }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4">Installation Steps</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Step 1
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body1">
                                Remove ladders and other obstructions from the pool deck. Measure the pool and the cover
                                to make sure of a fit. For a 3 ft. grid cover, the cover size will be 2 to 3 ft. larger
                                than the pool in both directions for a 5 ft. grid cover is oversized 1 1/2 to 2 ft. Lay
                                the cover over the pool and adjust for equal overlap to all corners of the pool. Overlap
                                will be 11 to 14″ 5 ft. grid, 13 to 18″ 3 ft. grid. Pull cover tightly to remove packing
                                wrinkles. Install buckles, springs and spring covers onto the straps and the 8 corner
                                strap locations. Adjust to equal lengths.
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Step 2
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body1">
                                Install anchors at the corner of the cover first. To determine the anchor points,
                                measure 18″ from the cover edge and at right angle to the cover, over the straps and
                                mark. Now using a 3/4″ concrete bit in a rotaryhammer drill, drill 2 to 3″ deep holes
                                and clean out concrete dust. Hammer the brass anchors using the tamping tool provided
                                and insert the anchor screws into place using hex key provided.
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Step 3
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body1">
                                Attach the corner spring to the anchors using the installation rod provided. Use the rod
                                by keeping the notched area towards the pool and pulling the handle away from the pool
                                until the heel is free from the anchor. Insure the overlap is equal on sides and ends,
                                adjustments can be made by adjusting strap lengths at the buckle. Springs should be 10
                                to 20% compressed for proper tension.
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Step 4
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body1">
                                Installing remaining buckles, springs and spring covers on the remaining straps. Install
                                brass anchors as before by measuring back 18″ from the cover edge in a straight line
                                with each strap. Place springs on the anchors with the rod starting at the centre of
                                each side and working alternately from side to side.
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Installation of wood anchors
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body1">
                                Using a 1 1/2 wood drill 1/8 deep recess hole. At the centre of this hole drill a 3/4″ x
                                2″ deep hole, or through the wood. Drop in your anchor and fasten with the 4 wood screws
                                provided.
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Form fit installation
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body1">
                                Lay out cover as above insuring the overlap is equal around the perimeter and starting
                                at the centre of the sides and mark and drill for the brass anchors (4 – 2 on each
                                side). Secure the springs alternating from side to side. Mark and drill the remaining
                                holes and continue around pool again alternating from side to side.
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Completion
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body1">
                                On completition of the installation of your PSP safety cover, all springs should have
                                equal tension and your cover should be straight and wrinkle free.
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Removing your PSP Safety Cover
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body1">
                                Insert the installation rod between the spring and the anchor. Tip the rod towards the
                                cover to slip the spring off the anchor. Carefully pull the cover off the pool. Clean
                                it, fold it, put it back in the mesh bag provided and hang it using your hex key, sink
                                your anchors to deck level for summer.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};
export default InstallationStepsSection;
