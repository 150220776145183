import React from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import spaCoverBg from '@assets/spaCover/overview/spaCoverBg.jpg';
const SpaCoversSection: React.FunctionComponent = () => {
    return (
        <Box display="flex" height="100vh" width="100%" alignItems="flex-end">
            <Box
                sx={{
                    backgroundImage: `url(${spaCoverBg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'absolute',
                    height: '100vh',
                    width: '100%',
                    filter: 'brightness(40%)',
                    zIndex: -1
                }}
            />
            <Container>
                <Stack spacing={2} pb={12}>
                    <Typography variant="h3" color="white">
                        Spa Covers
                    </Typography>
                    <Typography variant="h5" color="white">
                        We use the best quality marine grade vinyl, which is UV treated to resist fading, cracking and
                        mildew. Our cover skins are computer cut to ensure a perfect fit, while the remainder of the hot
                        tub cover is hand made with top quality workmanship. PSP spa covers are perfect for any custom
                        spa and are available in 6 beautiful colors
                    </Typography>
                </Stack>
            </Container>
        </Box>
    );
};
export default SpaCoversSection;
