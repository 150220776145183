import { setAlert } from '@store/appStore';
import store from '@store/index';

export const showAlertMessage = (message?: string) => {
    store.dispatch(
        setAlert({
            message: message || '',
            open: true,
            severity: 'success'
        })
    );
};
