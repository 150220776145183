import React from 'react';
import { Container, Box, Grid, Stack, Typography } from '@mui/material';
import helps from '@assets/safetyCover/overview/helps.png';
import pet from '@assets/safetyCover/overview/pet.png';
import children from '@assets/safetyCover/overview/children.png';
const HelpsSection: React.FunctionComponent = () => {
    return (
        <Container>
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <Stack
                        borderTop={1}
                        borderRight={1}
                        borderBottom={1}
                        borderLeft={1}
                        borderRadius={5}
                        borderColor="gray"
                        height="100%"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                        p={4}
                    >
                        <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
                            <Box component="img" src={pet} width={80} />
                            <Typography width="70%" variant="h5" textAlign="center">
                                Helps to prevent accidental intrusion by pets and children
                            </Typography>
                            <Box component="img" src={children} width={80} />
                        </Stack>
                        <Box component="img" src={helps} width="100%" />
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
};
export default HelpsSection;
