import React from 'react';
import { Snackbar, Slide, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducer } from '@store/index';
import { setAlert } from '@store/appStore';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ReportIcon from '@mui/icons-material/Report';

const SlideTransition = (props: any) => {
    return <Slide {...props} direction="down" />;
};

const iconStyles = {
    fontSize: { xs: 20, sm: 25, md: 30 },
    marginRight: 1
};

const AlertMessage = () => {
    const { alert } = useSelector((state: RootReducer) => state.app);
    const dispatch = useDispatch();

    const handleClose = () => {
        alert.open = false;
        dispatch(setAlert(alert));
    };

    const handleMainTitle = () => {
        if (alert.severity === 'success') {
            return (
                <Typography color="green" typography="body-2">
                    Operation Successfully!
                </Typography>
            );
        }
        return (
            <Typography color="error" typography="body-2">
                Oops! Something went wrong
            </Typography>
        );
    };

    return (
        <Snackbar
            open={alert.open}
            autoHideDuration={5000}
            onClose={handleClose}
            TransitionComponent={SlideTransition}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        >
            <Alert
                variant="standard"
                severity={alert.severity}
                onClose={handleClose}
                sx={{
                    borderRadius: 25,
                    paddingX: 4,
                    alignItems: 'center',
                    backgroundColor: 'white',
                    boxShadow: 8
                }}
                iconMapping={{
                    success: <CheckCircleIcon fontSize="inherit" sx={iconStyles} />,
                    info: <InfoIcon fontSize="inherit" sx={iconStyles} />,
                    warning: <WarningIcon fontSize="inherit" sx={iconStyles} />,
                    error: <ReportIcon fontSize="inherit" sx={iconStyles} />
                }}
                action={
                    <IconButton onClick={handleClose}>
                        <CloseIcon sx={{ fontSize: 25, color: 'gray' }} />
                    </IconButton>
                }
            >
                {handleMainTitle()}
                <Typography color="black" typography="body-1">
                    {alert.message}
                </Typography>
            </Alert>
        </Snackbar>
    );
};

export default AlertMessage;
