import React from 'react';
import { Container, Box, Grid, Typography, Divider } from '@mui/material';
import shape1 from '@assets/safetyCover/techSpecs/shapes/shape1.png';
import shape2 from '@assets/safetyCover/techSpecs/shapes/shape2.png';
import shape3 from '@assets/safetyCover/techSpecs/shapes/shape3.png';
import shape4 from '@assets/safetyCover/techSpecs/shapes/shape4.png';
import shape5 from '@assets/safetyCover/techSpecs/shapes/shape5.png';
import shape6 from '@assets/safetyCover/techSpecs/shapes/shape6.png';
import shape7 from '@assets/safetyCover/techSpecs/shapes/shape7.png';
import shape8 from '@assets/safetyCover/techSpecs/shapes/shape8.png';
const BasicShapesSection: React.FunctionComponent = () => {
    return (
        <Container>
            <Grid container>
                <Grid item xs={12} mb={4}>
                    <Divider style={{ width: '100%', background: 'lightgrey', borderBottomWidth: 3 }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4">Basic Shapes</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                We provide some guidelines for measuring your pool. If your pool shape is one of the
                                standard ones as shown below, please provide all the measurements marked.The
                                measurements should be accurate and clear for the perfect fit cover.If your pool is a
                                custom shape, please follow this AB Measurement Guideline.
                            </Typography>
                        </Grid>
                        <Grid item md={5} textAlign="center">
                            <Box component="img" src={shape1} width="100%" />
                            <Typography variant="subtitle1">Squares / Rectangle</Typography>
                        </Grid>
                        <Grid item md={5} textAlign="center">
                            <Box component="img" src={shape2} width="100%" />
                            <Typography variant="subtitle1">Grecian</Typography>
                        </Grid>
                        <Grid item md={5} textAlign="center">
                            <Box component="img" src={shape3} width="100%" />
                            <Typography variant="subtitle1">Oval</Typography>
                        </Grid>
                        <Grid item md={5} textAlign="center">
                            <Box component="img" src={shape4} width="100%" />
                            <Typography variant="subtitle1">1 Roman End</Typography>
                        </Grid>
                        <Grid item md={5} textAlign="center">
                            <Box component="img" src={shape5} width="100%" />
                            <Typography variant="subtitle1">2 Roman End</Typography>
                        </Grid>
                        <Grid item md={5} textAlign="center">
                            <Box component="img" src={shape6} width="100%" />
                            <Typography variant="subtitle1">Full L</Typography>
                        </Grid>
                        <Grid item md={5} textAlign="center">
                            <Box component="img" src={shape7} width="100%" />
                            <Typography variant="subtitle1">Lazy L</Typography>
                        </Grid>
                        <Grid item md={5} textAlign="center">
                            <Box component="img" src={shape8} width="100%" />
                            <Typography variant="subtitle1">Freedom</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};
export default BasicShapesSection;
