import React from 'react';
import { Container, Box, Grid, Stack, Typography, Divider } from '@mui/material';
import safetyCover from '@assets/safetyCover/techSpecs/safetyCover.png';
import black from '@assets/safetyCover/techSpecs/colors/black.jpg';
import blue from '@assets/safetyCover/techSpecs/colors/blue.jpg';
import green from '@assets/safetyCover/techSpecs/colors/green.jpg';
import taupe from '@assets/safetyCover/techSpecs/colors/taupe.jpg';
import grey from '@assets/safetyCover/techSpecs/colors/grey.jpg';
import ultraTaupe from '@assets/safetyCover/techSpecs/colors/ultraTaupe.jpg';
import ultraBlue from '@assets/safetyCover/techSpecs/colors/ultraBlue.jpg';
import ultraGreen from '@assets/safetyCover/techSpecs/colors/ultraGreen.jpg';
import ultraBlack from '@assets/safetyCover/techSpecs/colors/ultraBlack.jpg';
import ultraGrey from '@assets/safetyCover/techSpecs/colors/ultraGrey.jpg';

const AvailableColorsSection: React.FunctionComponent = () => {
    return (
        <Container>
            <Stack alignItems="center" spacing={4}>
                <Divider style={{ width: '100%', background: 'lightgrey', borderBottomWidth: 4 }} />
                <Box component="img" src={safetyCover} width={350} />
                <Divider style={{ width: '100%', background: 'lightgrey', borderBottomWidth: 3 }} />
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h4">Available Colors</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" fontWeight={600}>
                                    Standard Mesh
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Stack alignItems="center" spacing={2}>
                                    <Box component="img" src={taupe} width="100%" borderRadius={10} />
                                    <Typography variant="h5">Taupe</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Stack alignItems="center" spacing={2}>
                                    <Box component="img" src={blue} width="100%" borderRadius={10} />
                                    <Typography variant="h5">Blue</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Stack alignItems="center" spacing={2}>
                                    <Box component="img" src={green} width="100%" borderRadius={10} />
                                    <Typography variant="h5">Green</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Stack alignItems="center" spacing={2}>
                                    <Box component="img" src={grey} width="100%" borderRadius={10} />
                                    <Typography variant="h5">Grey</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Stack alignItems="center" spacing={2}>
                                    <Box component="img" src={black} width="100%" borderRadius={10} />
                                    <Typography variant="h5">Black</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" fontWeight={600}>
                                    Ultra Mesh
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Stack alignItems="center" spacing={2}>
                                    <Box component="img" src={ultraTaupe} width="100%" borderRadius={10} />
                                    <Typography variant="h5">Taupe</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Stack alignItems="center" spacing={2}>
                                    <Box component="img" src={ultraBlue} width="100%" borderRadius={10} />
                                    <Typography variant="h5">Blue</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Stack alignItems="center" spacing={2}>
                                    <Box component="img" src={ultraGreen} width="100%" borderRadius={10} />
                                    <Typography variant="h5">Green</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Stack alignItems="center" spacing={2}>
                                    <Box component="img" src={ultraGrey} width="100%" borderRadius={10} />
                                    <Typography variant="h5">Grey</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Stack alignItems="center" spacing={2}>
                                    <Box component="img" src={ultraBlack} width="100%" borderRadius={10} />
                                    <Typography variant="h5">Black</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Stack>
        </Container>
    );
};
export default AvailableColorsSection;
