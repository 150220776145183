import React from 'react';
import { makeStyles } from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { RootReducer } from '@store/index';
import CircularProgressWithLabel from '@components/Utilities/CircularProgressWithLabel';
import { Stack, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
    backdrop: {
        zIndex: 9999,
        color: 'black'
    }
}));

const Loader = () => {
    const { progressBar, loaderMessage } = useSelector((state: RootReducer) => state.app);
    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={true}>
            <Stack direction="column" alignItems="center" spacing={2}>
                {progressBar ? (
                    <CircularProgressWithLabel size={100} value={progressBar} />
                ) : (
                    <CircularProgress size={100} />
                )}
                <Typography color="text.secondary">{loaderMessage}</Typography>
            </Stack>
        </Backdrop>
    );
};

export default Loader;
