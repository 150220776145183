import axios from 'axios';

//const BASE_URL = 'http://localhost:3000/api/v1';

const BASE_URL = 'https://admin.pspfabstore.com/api/v1';
export const axiosPrivate = axios.create({
    baseURL: BASE_URL
});
axiosPrivate.interceptors.request.use(async (config) => {
    config.headers!['Content-Type'] = 'application/json;charset=UTF-8';
    return config;
});
