import React from 'react';
import { Stack } from '@mui/material';
import SpaCoversSection from 'src/ui/components/SpaCover/Overview/SpaCoversSection';
import WarrantySection from 'src/ui/components/SpaCover/Overview/WarrantySection';
import PSPHotTubSection from 'src/ui/components/SpaCover/Overview/PSPHotTubSection';
import FeaturesSection from 'src/ui/components/SpaCover/Overview/FeaturesSection';
const SpaCoverOverview: React.FunctionComponent = () => {
    return (
        <Stack minHeight="100vh" alignItems="center" spacing={8}>
            <SpaCoversSection />
            <PSPHotTubSection />
            <WarrantySection />
            <FeaturesSection />
        </Stack>
    );
};
export default SpaCoverOverview;
