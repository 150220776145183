import React from 'react';
import { Box, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import stockTable from '@assets/safetyCover/techSpecs/stockTable.png';
const StockSection: React.FunctionComponent = () => {
    return (
        <Container>
            <Grid container>
                <Grid item xs={12} mb={4}>
                    <Divider style={{ width: '100%', background: 'lightgrey', borderBottomWidth: 3 }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4">Stock</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Stack spacing={4}>
                        <Typography variant="body1">
                            We stock covers with standard shapes and sizes ready to deliver within 24 hours.
                        </Typography>
                        <Box component="img" src={stockTable} width="50%" />
                        <Typography variant="body1">
                            All covers are standard mesh 5′ x 5′ grid.Covers include all hardware & storage bag.
                            Available in Green and Taupe.
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
};
export default StockSection;
