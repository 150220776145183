import React from 'react';
import { Container, Box, Stack, Typography } from '@mui/material';
import features from '@assets/safetyCover/overview/features.png';
const FreedomSection: React.FunctionComponent = () => {
    return (
        <Container>
            <Stack justifyContent="center" alignItems="center" spacing={4}>
                <Typography variant="h5" textAlign="center">
                    With freedom comes choice
                </Typography>
                <Typography variant="body1" textAlign="center">
                    We live in a world of <strong>choice</strong>. When choosing a safety cover for your swimming pool
                    one must choose wisely. Here are some of the reason why choose a PSP Safety Cover.
                </Typography>
                <Box component="img" src={features} width="100%" />
            </Stack>
        </Container>
    );
};
export default FreedomSection;
