import * as Yup from 'yup';
import React from 'react';
import { Grid, Typography, TextField, FormControl, InputLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import CountriesSelect from '@components/Utilities/Selects/CountriesSelect';
export const contactInformationSchema = Yup.object({
    contactInformation: Yup.object().shape({
        name: Yup.string().required('Name is required'),
        address: Yup.string().required('Address is required'),
        apartment: Yup.string().required('Apartment is required'),
        phone: Yup.string().required('Phone is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        email: Yup.string().required('Email is required'),
        zipCode: Yup.string().required('Zip code is required'),
        country: Yup.string().required('Country is required')
    })
});
interface ContactInformationProps {
    warrantyRegistrationForm: any;
}
const ContactInformation: React.FunctionComponent<ContactInformationProps> = (props) => {
    const { warrantyRegistrationForm } = props;
    const {
        control,
        formState: { errors }
    } = warrantyRegistrationForm;
    return (
        <Grid container>
            <Grid item xs={12} md={4}>
                <Typography variant="h6" fontWeight={600}>
                    Contact Information
                </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="contactInformation.name"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    fullWidth
                                    required
                                    label="Name of the purchaser"
                                    variant="standard"
                                    value={value}
                                    onChange={onChange}
                                    error={!!errors.contactInformation?.name}
                                    helperText={
                                        errors.contactInformation?.name && errors.contactInformation?.name.message
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="contactInformation.address"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    fullWidth
                                    required
                                    label="Address"
                                    variant="standard"
                                    value={value}
                                    onChange={onChange}
                                    error={!!errors.contactInformation?.address}
                                    helperText={
                                        errors.contactInformation?.address && errors.contactInformation?.address.message
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="contactInformation.apartment"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    fullWidth
                                    required
                                    label="Apt, Suite, Building"
                                    variant="standard"
                                    value={value}
                                    onChange={onChange}
                                    error={!!errors.contactInformation?.apartment}
                                    helperText={
                                        errors.contactInformation?.apartment &&
                                        errors.contactInformation?.apartment.message
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="contactInformation.phone"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    fullWidth
                                    required
                                    label="Phone"
                                    variant="standard"
                                    value={value}
                                    onChange={onChange}
                                    error={!!errors.contactInformation?.phone}
                                    helperText={
                                        errors.contactInformation?.phone && errors.contactInformation?.phone.message
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="contactInformation.city"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    fullWidth
                                    required
                                    label="City"
                                    variant="standard"
                                    value={value}
                                    onChange={onChange}
                                    error={!!errors.contactInformation?.city}
                                    helperText={
                                        errors.contactInformation?.city && errors.contactInformation?.city.message
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="contactInformation.state"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    fullWidth
                                    required
                                    label="State / Province / Region"
                                    variant="standard"
                                    value={value}
                                    onChange={onChange}
                                    error={!!errors.contactInformation?.state}
                                    helperText={
                                        errors.contactInformation?.state && errors.contactInformation?.state.message
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="contactInformation.email"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    fullWidth
                                    required
                                    label="Email"
                                    variant="standard"
                                    value={value}
                                    onChange={onChange}
                                    error={!!errors.contactInformation?.email}
                                    helperText={
                                        errors.contactInformation?.email && errors.contactInformation?.email.message
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="contactInformation.zipCode"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    fullWidth
                                    required
                                    label="Postal / Zip Code"
                                    variant="standard"
                                    value={value}
                                    onChange={onChange}
                                    error={!!errors.contactInformation?.zipCode}
                                    helperText={
                                        errors.contactInformation?.zipCode && errors.contactInformation?.zipCode.message
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="contactInformation.country"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormControl fullWidth required error={!!errors.contactInformation?.country}>
                                    <InputLabel id="country-label">Country</InputLabel>
                                    <CountriesSelect
                                        label="Country"
                                        labelId="country-label"
                                        variant="standard"
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors.contactInformation?.country}
                                    />
                                </FormControl>
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default ContactInformation;
