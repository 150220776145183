import React from 'react';
import { Container, Box, Grid, Stack, Typography, Divider } from '@mui/material';
import spaCover from '@assets/spaCover/techSpecs/spaCover.jpg';
import black from '@assets/spaCover/techSpecs/colors/black.png';
import lightGrey from '@assets/spaCover/techSpecs/colors/lightGrey.png';
import chocolate from '@assets/spaCover/techSpecs/colors/chocolate.png';
import charcoal from '@assets/spaCover/techSpecs/colors/charcoal.png';
import chesnut from '@assets/spaCover/techSpecs/colors/chesnut.jpg';
import darkGrey from '@assets/spaCover/techSpecs/colors/darkGrey.png';
const AvailableColorsSection: React.FunctionComponent = () => {
    return (
        <Container>
            <Stack alignItems="center" spacing={4}>
                <Divider style={{ width: '100%', background: 'lightgrey', borderBottomWidth: 4 }} />
                <Box component="img" src={spaCover} width={350} borderRadius={10} />
                <Divider style={{ width: '100%', background: 'lightgrey', borderBottomWidth: 3 }} />
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h4">Available Colors</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={4}>
                                <Stack alignItems="center" spacing={2}>
                                    <Box component="img" src={black} width="100%" borderRadius={10} />
                                    <Typography variant="h5">Black</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Stack alignItems="center" spacing={2}>
                                    <Box component="img" src={charcoal} width="100%" borderRadius={10} />
                                    <Typography variant="h5">Charcoal</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Stack alignItems="center" spacing={2}>
                                    <Box component="img" src={chocolate} width="100%" borderRadius={10} />
                                    <Typography variant="h5">Chocolate</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Stack alignItems="center" spacing={2}>
                                    <Box component="img" src={chesnut} width="100%" borderRadius={10} />
                                    <Typography variant="h5">Chestnut</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Stack alignItems="center" spacing={2}>
                                    <Box component="img" src={darkGrey} width="100%" borderRadius={10} />
                                    <Typography variant="h5">Dark Grey</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Stack alignItems="center" spacing={2}>
                                    <Box component="img" src={lightGrey} width="100%" borderRadius={10} />
                                    <Typography variant="h5">Light Grey</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Stack>
        </Container>
    );
};
export default AvailableColorsSection;
