import React from 'react';
import { Divider, Stack } from '@mui/material';
import { FixNavbarSpace } from '@components/Utilities/Navbar';
import AvailableColors from '@components/SpaCover/TechSpecs/AvailableColors';
import BasicShapesSection from '@components/SpaCover/TechSpecs/BasicShapes';
import MeasuringSection from '@components/SpaCover/TechSpecs/Measuring';
import OptionsSection from '@components/SpaCover/TechSpecs/Options';
import FeaturesSection from '@components/SpaCover/TechSpecs/Features';
import Warranty from '@components/SpaCover/TechSpecs/Warranty';

const SpaCoverTechSpecs: React.FunctionComponent = () => {
    return (
        <Stack minHeight="100vh" alignItems="center" spacing={8}>
            <FixNavbarSpace />
            <AvailableColors />
            <BasicShapesSection />
            <MeasuringSection />
            <OptionsSection />
            <FeaturesSection />
            <Warranty />
        </Stack>
    );
};
export default SpaCoverTechSpecs;
