export const COUNTRIES = [
    'Bermuda',
    'Canada',
    'Greenland',
    'Saint Pierre and Miquelon',
    'United States',
    'Belize',
    'Costa Rica',
    'El Salvador',
    'Guatemala',
    'Honduras',
    'Mexico',
    'Nicaragua',
    'Panama',
    'Argentina',
    'Bolivia',
    'Bouvet Island',
    'Brazil',
    'Chile',
    'Colombia',
    'Ecuador',
    'Falkland Islands',
    'French Guiana',
    'Guyana',
    'Paraguay',
    'Peru',
    'South Georgia and South Sandwich Islands',
    'Suriname',
    'Uruguay',
    'Venezuela',
    'Anguilla',
    'Antigua and Barbuda',
    'Aruba',
    'Bahamas',
    'Barbados',
    'Bonaire',
    'Saint Eustatius and Saba',
    'British Virgin Islands',
    'Cayman Islands',
    'Cuba',
    'Curacao',
    'Dominica',
    'Dominican Republic',
    'Grenada',
    'Guadeloupe',
    'Haiti',
    'Jamaica',
    'Martinique',
    'Montserrat',
    'Puerto Rico',
    'Saint Barthelemy',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Martin',
    'Saint Vincent and the Grenadines',
    'Sint Maarten',
    'Trinidad and Tobago',
    'Turks and Caicos Islands',
    'Virgin Islands'
];
