const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

export const getBase64FromInputFile = (file: any): Promise<string> => {
    return new Promise((resolve) => {
        let baseURL: string = '';
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            baseURL = reader.result as string;
            resolve(baseURL);
        };
    });
};

export const getBase64MimeType = (base64String: string) => {
    const header = base64String.split(',')[0];
    return header.replace('data:', '').replace(';base64', '');
};

export const getBase64Extension = (base64String: string) => {
    const mimeType = getBase64MimeType(base64String);
    return mimeType.split('/')[1];
};

export const getBase64 = (base64String: string) => {
    return base64String.split(',')[1];
};

export const isBase64 = (string: string) => {
    return base64regex.test(string);
};
