import React from 'react';
import { Container, Box, Grid, Stack, Typography } from '@mui/material';
import astm from '@assets/spaCover/overview/astm.png';
import warranty from '@assets/spaCover/overview/warranty.png';
import spaColors from '@assets/spaCover/overview/spaColors.jpg';
import black from '@assets/spaCover/techSpecs/colors/black.png';
import lightGrey from '@assets/spaCover/techSpecs/colors/lightGrey.png';
import chocolate from '@assets/spaCover/techSpecs/colors/chocolate.png';
import charcoal from '@assets/spaCover/techSpecs/colors/charcoal.png';
import chesnut from '@assets/spaCover/techSpecs/colors/chesnut.jpg';
import darkGrey from '@assets/spaCover/techSpecs/colors/darkGrey.png';
const WarrantySection: React.FunctionComponent = () => {
    return (
        <Container>
            <Grid container spacing={8}>
            <Grid item xs={12}>
            <Box p={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" textAlign="center">
                            We offer a variety of tapers, skirt sizes and colors to give you more selection to best suit your needs.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Box
                            component="img"
                            src={spaColors}
                            sx={{
                                width: { xs: '100%', md: '50%' },
                                borderRadius: 10
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="center" spacing={2}>
                            <Box
                                component="img"
                                src={black}
                                sx={{
                                    width: { xs: '45px', md: '77px' },
                                    height: { xs: '45px', md: '77px' },
                                    borderRadius: '50%'
                                }}
                            />
                            <Box
                                component="img"
                                src={charcoal}
                                sx={{
                                    width: { xs: '45px', md: '77px' },
                                    height: { xs: '45px', md: '77px' },
                                    borderRadius: '50%'
                                }}
                            />
                            <Box
                                component="img"
                                src={chocolate}
                                sx={{
                                    width: { xs: '45px', md: '77px' },
                                    height: { xs: '45px', md: '77px' },
                                    borderRadius: '50%'
                                }}
                            />
                            <Box
                                component="img"
                                src={chesnut}
                                sx={{
                                    width: { xs: '45px', md: '77px' },
                                    height: { xs: '45px', md: '77px' },
                                    borderRadius: '50%'
                                }}
                            />
                            <Box
                                component="img"
                                src={darkGrey}
                                sx={{
                                    width: { xs: '45px', md: '77px' },
                                    height: { xs: '45px', md: '77px' },
                                    borderRadius: '50%'
                                }}
                            />
                            <Box
                                component="img"
                                src={lightGrey}
                                sx={{
                                    width: { xs: '45px', md: '77px' },
                                    height: { xs: '45px', md: '77px' },
                                    borderRadius: '50%'
                                }}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} md={6}>
                            <Stack height="100%" justifyContent="center" alignItems="center" spacing={4}>
                                <Typography variant="h5" textAlign="center">
                                    ASTM Safety Compliance
                                </Typography>
                                <Box component="img" src={astm} width="70%" />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box display="flex" height="100%" justifyContent="center" alignItems="center">
                                <Box component="img" src={warranty} width="70%" />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};
export default WarrantySection;
