import React from 'react';
import { Container, Grid, Typography, Stack, Divider } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
const WarrantySection: React.FunctionComponent = () => {
    return (
        <Container>
            <Grid container mb={12}>
                <Grid item xs={12} mb={4}>
                    <Divider style={{ width: '100%', background: 'lightgrey', borderBottomWidth: 3 }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4">Warranty</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Stack spacing={2}>
                        <Typography variant="subtitle2" fontWeight={600}>
                            3 YEAR LIMITED WARRANTY
                        </Typography>
                        <Typography variant="body1">
                            PSP FABRICATING INC. Warrants the purchaser of the spa cover that any defects in material or
                            workmanship will be repaired or replaced, at the company’s discretion, free of charge within
                            one (1) year of the purchase date.
                        </Typography>
                        <Stack p={1}>
                            <Typography variant="body1">
                                • 2nd. year replacement charge 50% of the purchase price.
                            </Typography>
                            <Typography variant="body1">
                                • 3rd. year replacement charge 80% of the purchase price.
                            </Typography>
                        </Stack>
                        <Typography variant="body1">
                            Should you discover a defect, as covered by this limited warranty, during the warranty
                            period:
                        </Typography>
                        <Stack direction="row" spacing={1}>
                            <CheckIcon />
                            <Typography variant="body1">
                                PSP Fabricating will repair or replace, at its discretion, any defects, if upon
                                inspection the defect is found to be covered by the warranty.
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <CheckIcon />
                            <Typography variant="body1">
                                The repaired or replaced cover will be shipped pre-paid by a conventional surface
                                freight carrier.
                            </Typography>
                        </Stack>
                        <Typography variant="body1">The following are excluded from the warranty:</Typography>
                        <Stack direction="row" spacing={1}>
                            <CloseIcon />
                            <Typography variant="body1">
                                Foam breakage and/or torn vinyl. Foam breakage or deterioration and/or torn vinyl from
                                excessive weight caused by rain, water, snow, excessive heat, animals, and/or humans
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <CloseIcon />
                            <Typography variant="body1">
                                Chaffing, scuffing, and/or or worn holes in cover from dragging or storing it against
                                sharp objects.
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <CloseIcon />
                            <Typography variant="body1">
                                Cost of freight to PSP fabricating manufacturing facility.
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <CloseIcon />
                            <Typography variant="body1">
                                Fading of vinyl and thread: This is not considered a defect, but a naturally occurring
                                circumstance due to normal chemical maintenance, exposure to the sun, and aging of the
                                cover.
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <CloseIcon />
                            <Typography variant="body1">
                                Torn handles: handles are for opening and closing the cover only. They are not designed
                                to carry the cover
                            </Typography>
                        </Stack>
                        <Typography variant="body1">
                            For service under this warranty, or any information about our spa covers, call your dealer,
                            or contact us.
                        </Typography>
                        <Typography variant="body1">
                            IN ORDER TO COMPLETE YOUR{' '}
                            <Typography variant="caption" color="primary" fontSize={18}>
                                WARRANTY REGISTRATION FORM
                            </Typography>
                            , PLEASE HAVE YOUR SALES RECEIPT READY!
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
};
export default WarrantySection;
