import React, { useState } from 'react';
import { Stack } from '@mui/material';
import AssistantSection from 'src/ui/components/Home/AssistantSection';
import CoversSection from 'src/ui/components/Home/CoversSection';
import OurFacilitiesSection from '@components/Home/OurFacilitiesSection';
import ContactSection from '@components/Home/ContactSection';
import { FixNavbarSpace } from '@components/Utilities/Navbar';

const Home: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <Stack minHeight="100vh" alignItems="center" bgcolor="rgba(33,150,243,0.1)">
            <FixNavbarSpace />
            {/* <AssistantSection /> */}
            <CoversSection />
            <OurFacilitiesSection />
            <ContactSection />
        </Stack>
    );
};
export default Home;
