import store from '@store/index';
import { setIsLoading } from '@store/appStore';

export const showLoader = () => {
    store.dispatch(setIsLoading(true));
};

export const hideLoader = () => {
    store.dispatch(setIsLoading(false));
};
