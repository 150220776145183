import React from 'react';
import safetyCoverBg from '@assets/safetyCover/overview/safetyCoverBg.jpg';
import { Box, Container, Stack, Typography } from '@mui/material';
const SafetyCoversSection: React.FunctionComponent = () => {
    return (
        <Box display="flex" height="100vh" width="100%" alignItems="flex-end">
            <Box
                sx={{
                    backgroundImage: `url(${safetyCoverBg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'absolute',
                    height: '100vh',
                    width: '100%',
                    filter: 'brightness(40%)',
                    zIndex: -1
                }}
            />
            <Container>
                <Stack spacing={2} pb={12}>
                    <Typography variant="h3" color="white">
                        Safety Covers
                    </Typography>
                    <Typography variant="h5" color="white">
                        A safety cover over the backyard pool is one of the most essential components in any backyard
                        multi barriers safety system. These high load carrying covers completely block access to the
                        pool, while still allowing an adult to walk on the surface.
                    </Typography>
                </Stack>
            </Container>
        </Box>
    );
};
export default SafetyCoversSection;
