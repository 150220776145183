import React, { useMemo } from 'react';
import { Container, Divider, Stack } from '@mui/material';
import ContactInformation, { contactInformationSchema } from '@components/WarrantyRegistrationForm/ContactInformation';
import OrderInformation, { orderInformationSchema } from '@components/WarrantyRegistrationForm/OrderInformation';
import CustomerService, { customerServiceSchema } from '@components/WarrantyRegistrationForm/CustomerService';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { WarrantyRegistration } from '@api/WarrantyRegistrations/WarrantyRegistration';
import WarrantyRegistrationService from '@api/WarrantyRegistrations/WarrantyRegistrationCtrl';
import { hideLoader, showLoader } from '@utils/Loader';
import { showAlertError } from '@utils/Alerts/Error';
import { showAlertMessage } from '@utils/Alerts/Message';
const WarrantyRegistrationFormSection: React.FunctionComponent = () => {
    const warrantyRegistrationService = useMemo(() => new WarrantyRegistrationService(), []);

    const warrantyRegistrationForm = useForm<WarrantyRegistration>({
        resolver: yupResolver(contactInformationSchema.concat(orderInformationSchema.concat(customerServiceSchema)))
    });

    const onSubmitForm: SubmitHandler<WarrantyRegistration> = async (values) => {
        showLoader();
        try {
            await warrantyRegistrationService.addWarrantyRegistration(values);
            await warrantyRegistrationForm.reset();
            showAlertMessage('Warranty registration form sent successfully');
        } catch (error: any) {
            showAlertError(error);
        }
        hideLoader();
    };

    return (
        <Container>
            <form id="warranty-registration-form" onSubmit={warrantyRegistrationForm.handleSubmit(onSubmitForm)}>
                <Divider style={{ width: '100%', background: 'lightgrey', borderBottomWidth: 3 }} />
                <Stack minHeight="100vh" alignItems="center" spacing={8} my={8}>
                    <ContactInformation warrantyRegistrationForm={warrantyRegistrationForm} />
                    <OrderInformation warrantyRegistrationForm={warrantyRegistrationForm} />
                    <CustomerService warrantyRegistrationForm={warrantyRegistrationForm} />
                </Stack>
            </form>
        </Container>
    );
};
export default WarrantyRegistrationFormSection;
