import React from 'react';
import { Stack, StackProps, Typography } from '@mui/material';

interface PSPHotTubItemProps {
    icon: React.ReactNode;
    title: string;
    description?: string;
    justifyContent?: string;
}
const PSPHotTubItem: React.FunctionComponent<PSPHotTubItemProps> = (props) => {
    const { icon, title, description, justifyContent } = props;
    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ justifyContent: { xs: 'center', md: justifyContent || 'flex-start' } }}
        >
            {icon}
            <Stack>
                <Typography variant="body1" color="#5B5B5B">
                    {title}
                </Typography>
                {description && (
                    <Typography variant="body1" color="#5B5B5B">
                        {description}
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
};
export default PSPHotTubItem;
