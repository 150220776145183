import React from 'react';
import { Container, Box, Grid, Stack, Typography } from '@mui/material';
import hardware1 from '@assets/safetyCover/overview/hardware/hardware1.png';
import hardware2 from '@assets/safetyCover/overview/hardware/hardware2.png';
import hardware3 from '@assets/safetyCover/overview/hardware/hardware3.jpg';
import hardware4 from '@assets/safetyCover/overview/hardware/hardware4.png';
import hardware5 from '@assets/safetyCover/overview/hardware/hardware5.png';
import hardware6 from '@assets/safetyCover/overview/hardware/hardware6.jpg';
import hardware7 from '@assets/safetyCover/overview/hardware/hardware7.png';
import hardware8 from '@assets/safetyCover/overview/hardware/hardware8.png';
import hardware9 from '@assets/safetyCover/overview/hardware/hardware9.png';
import hardware10 from '@assets/safetyCover/overview/hardware/hardware10.png';
const HardwareSection: React.FunctionComponent = () => {
    return (
        <Container>
            <Typography variant="h4" mb={8}>
                Hardware
            </Typography>
            <Container maxWidth="md">
                <Grid container spacing={8} mb={12} justifyContent="center">
                    <Grid item xs={12} md={4}>
                        <Stack spacing={4} textAlign="center">
                            <Box boxShadow={8} borderRadius={5}>
                                <Box
                                    component="img"
                                    src={hardware1}
                                    sx={{ objectFit: 'contain', height: 230, width: '100%' }}
                                />
                            </Box>
                            <Typography variant="h5">Short Stainless Steel Spring</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Stack spacing={4} textAlign="center">
                            <Box boxShadow={8} borderRadius={5}>
                                <Box
                                    component="img"
                                    src={hardware2}
                                    sx={{ objectFit: 'contain', height: 230, width: '100%' }}
                                />
                            </Box>
                            <Typography variant="h5">Stainless Steel Spring</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Stack spacing={4} textAlign="center">
                            <Box boxShadow={8} borderRadius={5}>
                                <Box
                                    component="img"
                                    src={hardware3}
                                    sx={{ objectFit: 'contain', height: 230, width: '100%' }}
                                />
                            </Box>
                            <Typography variant="h5">Stainless Steel Buckle</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Stack spacing={4} textAlign="center">
                            <Box boxShadow={8} borderRadius={5}>
                                <Box
                                    component="img"
                                    src={hardware4}
                                    sx={{ objectFit: 'contain', height: 230, width: '100%' }}
                                />
                            </Box>
                            <Typography variant="h5">Hex Key</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Stack spacing={4} textAlign="center">
                            <Box boxShadow={8} borderRadius={5}>
                                <Box
                                    component="img"
                                    src={hardware5}
                                    sx={{ objectFit: 'contain', height: 230, width: '100%' }}
                                />
                            </Box>
                            <Typography variant="h5">Concrete Anchor</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Stack spacing={4} textAlign="center">
                            <Box boxShadow={8} borderRadius={5}>
                                <Box
                                    component="img"
                                    src={hardware6}
                                    sx={{ objectFit: 'contain', height: 230, width: '100%' }}
                                />
                            </Box>
                            <Typography variant="h5">Wood Anchor</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Stack spacing={4} textAlign="center">
                            <Box boxShadow={8} borderRadius={5}>
                                <Box
                                    component="img"
                                    src={hardware7}
                                    sx={{ objectFit: 'contain', height: 230, width: '100%' }}
                                />
                            </Box>
                            <Typography variant="h5">Garden Stake</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Stack spacing={4} textAlign="center">
                            <Box boxShadow={8} borderRadius={5}>
                                <Box
                                    component="img"
                                    src={hardware8}
                                    sx={{ objectFit: 'contain', height: 230, width: '100%' }}
                                />
                            </Box>
                            <Typography variant="h5">Installation Rod</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Stack spacing={4} textAlign="center">
                            <Box boxShadow={8} borderRadius={5}>
                                <Box
                                    component="img"
                                    src={hardware9}
                                    sx={{ objectFit: 'contain', height: 230, width: '100%' }}
                                />
                            </Box>
                            <Typography variant="h5">Anchor Stake</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Stack spacing={4} textAlign="center">
                            <Box boxShadow={8} borderRadius={5}>
                                <Box
                                    component="img"
                                    src={hardware10}
                                    sx={{ objectFit: 'contain', height: 230, width: '100%' }}
                                />
                            </Box>
                            <Typography variant="h5">Spring Cover</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
};
export default HardwareSection;
