import React from 'react';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import { Typography, TypographyProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    button: {
        textDecoration: 'none',
        color: 'black',
        '&:hover': {
            color: '#0086CA'
        },
        '&.active': {
            color: '#0086CA',
            fontWeight: 'bold !important'
        }
    },
    buttonNormal: {
        textDecoration: 'none',
        color: 'black',
        '&:hover': {
            color: '#0086CA'
        }
    }
}));

const TextButton: React.FunctionComponent<NavLinkProps & TypographyProps> = (props) => {
    const classes = useStyles();
    const location = useLocation();

    const getTextClass = () => {
        const fullUrl = `${location.pathname}${location.hash}`;
        if (fullUrl === props.to) {
            return classes.button;
        }
        return classes.buttonNormal;
    };

    return (
        <Typography variant={props.variant || 'body2'} component={NavLink} className={getTextClass()} to={props.to}>
            {props.children}
        </Typography>
    );
};

export default TextButton;
