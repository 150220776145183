import { ref, uploadString, getDownloadURL } from 'firebase/storage';
import { storage } from '@api/Firebase/Firebase';

export default {
    async uploadFileToStorage(path: string, imageBase64: string, mimeType: string): Promise<string> { //revisar esta funcion para bucket
        return new Promise(async (resolve, reject) => {
            try {
                const storageRef = ref(storage, path);
                const uploadTask = await uploadString(storageRef, imageBase64, 'base64', { contentType: mimeType });
                const url = await getDownloadURL(uploadTask.ref);
                resolve(url);
            } catch (error) {
                reject('Error uploading file: ' + error);
            }
        });
    }
};
