import React from 'react';
import { Container, Grid, Typography, Stack, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const WarrantySection: React.FunctionComponent = () => {
    return (
        <Container>
            <Grid container mb={12}>
                <Grid item xs={12} mb={4}>
                    <Divider style={{ width: '100%', background: 'lightgrey', borderBottomWidth: 3 }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4">Warranty</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Stack spacing={2}>
                        <Typography variant="subtitle2" fontWeight={600}>
                            20 YEAR LIMITED WARRANTY
                        </Typography>
                        <Typography variant="body1">
                            PSP FABRICATING INC. Warrants the purchaser of our safety cover that any defects in material
                            or workmanship will be repaired or replaced, at the company’s discretion, free of charge
                            within five (5) years of the purchase date.
                        </Typography>
                        <Typography variant="body1">
                            • 6 to 20 years replacement @ 50% of the purchase price.
                        </Typography>
                        <Typography variant="body1">The following are excluded from the warranty:</Typography>
                        <Stack direction="row" spacing={1}>
                            <CloseIcon />
                            <Typography variant="body1">
                                Your water level drops below 18” of the deck during the snow season.
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <CloseIcon />
                            <Typography variant="body1">
                                Your PSP Safety Cover is used on any pool for which it was not designed.
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <CloseIcon />
                            <Typography variant="body1">
                                Your cover is subject to obvious misuse, improper installation, or damage caused by
                                falling sharp objects (i.e. tree limbs).
                            </Typography>
                        </Stack>
                        <Typography variant="body1">
                            PSP Fabricating does not cover service, installation, or freight under this warranty.
                        </Typography>
                        <Typography variant="body1">
                            For service under this warranty, or any information about our spa covers, call your dealer,
                            or contact us.
                        </Typography>
                        <Typography variant="body1">
                            IN ORDER TO COMPLETE YOUR{' '}
                            <Typography variant="caption" color="primary" fontSize={18}>
                                WARRANTY REGISTRATION FORM
                            </Typography>
                            , PLEASE HAVE YOUR SALES RECEIPT READY!
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
};
export default WarrantySection;
