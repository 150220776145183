import React from 'react';
import { Container, Grid, Typography, Stack, Divider } from '@mui/material';
const OptionsSection: React.FunctionComponent = () => {
    return (
        <Container>
            <Grid container>
                <Grid item xs={12} mb={4}>
                    <Divider style={{ width: '100%', background: 'lightgrey', borderBottomWidth: 3 }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4">Options</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Stack spacing={2}>
                        <Typography variant="body1">
                            We offer a variety of tapers, skirt sizes and colors to give you more selection to best suit
                            your needs.
                        </Typography>
                        <Typography variant="subtitle2" fontWeight={600}>
                            Features of our Spa covers.
                        </Typography>
                        <Typography variant="body1">
                            Made of high quality UV and mildew inhibitors marine grade vinyl with:
                        </Typography>
                        <Stack p={1}>
                            <Typography variant="body1">• 1.5 lb., 2 lb. density foam</Typography>
                            <Typography variant="body1">• 2 inch aluminum C channel</Typography>
                            <Typography variant="body1">• Vapour barrier protection</Typography>
                            <Typography variant="body1">• Drainage</Typography>
                            <Typography variant="body1">• Locking straps</Typography>
                            <Typography variant="body1">• Reflective shield undersides</Typography>
                            <Typography variant="body1">• ASTM safety compliance</Typography>
                        </Stack>
                        <Typography variant="body1">
                            Skirt – Our standard skirt size is 2.5 inch. As per your acrylic size we manufacture skirts
                            with a longer size and you can also have the option of no skirt. We also offer inserts with
                            higher density.
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
};
export default OptionsSection;
