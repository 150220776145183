import { MenuItem, Select, SelectProps, Typography } from '@mui/material';
import { COUNTRIES } from '@src/shared/constants/countries';
import React from 'react';
const CountriesSelect: React.FunctionComponent<SelectProps> = (props) => {
    return (
        <Select
            variant="standard"
            defaultValue="Canada"
            renderValue={(selected: any) => <Typography>{selected}</Typography>}
            {...props}
        >
            {COUNTRIES.sort().map((country, index) => {
                return (
                    <MenuItem key={index} value={country}>
                        {country}
                    </MenuItem>
                );
            })}
        </Select>
    );
};

export default CountriesSelect;
