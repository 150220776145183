import React from 'react';
import { Container, Box, Grid, Stack, Typography } from '@mui/material';
import PSPHotTub from '@assets/spaCover/overview/PSPHotTub.jpg';
import HotTubItem from '@components/SpaCover/Overview/HotTubItem';
import StarIcon from '@mui/icons-material/Star';
import ComputerIcon from '@mui/icons-material/Computer';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import PsychologyIcon from '@mui/icons-material/Psychology';
import DomainIcon from '@mui/icons-material/Domain';
import SpaIcon from '@mui/icons-material/Spa';
import LayersIcon from '@mui/icons-material/Layers';
import PublicIcon from '@mui/icons-material/Public';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ForumIcon from '@mui/icons-material/Forum';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
const PSPHotTubSection: React.FunctionComponent = () => {
    return (
        <Container>
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <Stack spacing={2}>
                        <Typography variant="h5" textAlign="center">
                            Why choose a PSP Hot Tub Cover?
                        </Typography>
                        <Typography variant="body1" textAlign="center">
                            PSP Fabricating Inc. is Canada’s No. 1 choice when it comes to hot tub covers. We are the
                            only Canadian hot tub cover manufacturer that produces its own high-density EPS core on
                            site. No other hot tub cover manufacturer in Canada has the capacity or facilities to
                            produce their own EPS core. Manufacturing our own EPS core comes with the ability to design
                            cover templates for a wide variety of hot tub shapes and sizes available on the market.
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} md={4}>
                            <Grid container spacing={4} justifyContent="center">
                                <Grid item xs={12}>
                                    <HotTubItem
                                        icon={<StarIcon sx={{ fontSize: 40, color: '#5B5B5B' }} />}
                                        title="Top quality marine grade vinyl"
                                        description="which is UV treated to resist fading, cracking and mildew"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <HotTubItem
                                        icon={<ComputerIcon sx={{ fontSize: 40, color: '#5B5B5B' }} />}
                                        title="Computerised state-of-the-art equipment to ensure a perfect fit."
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <HotTubItem
                                        icon={<DesignServicesIcon sx={{ fontSize: 40, color: '#5B5B5B' }} />}
                                        title="Oversize and odd shaped in-ground hot tub covers specialist"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <HotTubItem
                                        icon={<PsychologyIcon sx={{ fontSize: 40, color: '#5B5B5B' }} />}
                                        title="Swim Spa covers experts"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <HotTubItem
                                        icon={<DomainIcon sx={{ fontSize: 40, color: '#5B5B5B' }} />}
                                        title="OEM vendor"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={false} md={4} sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <Stack justifyContent="center">
                                <Box component="img" src={PSPHotTub} width="100%" borderRadius={10} />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container spacing={4} justifyContent="center">
                                <Grid item xs={12}>
                                    <HotTubItem
                                        icon={<LayersIcon sx={{ fontSize: 40, color: '#5B5B5B' }} />}
                                        title="Bulk orders"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <HotTubItem
                                        icon={<PublicIcon sx={{ fontSize: 40, color: '#5B5B5B' }} />}
                                        title="Exports / International customers"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <HotTubItem
                                        icon={<SupportAgentIcon sx={{ fontSize: 40, color: '#5B5B5B' }} />}
                                        title="Personalized customer service"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <HotTubItem
                                        icon={<ForumIcon sx={{ fontSize: 40, color: '#5B5B5B' }} />}
                                        title="Technical Support"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <HotTubItem
                                        icon={<AutorenewIcon sx={{ fontSize: 40, color: '#5B5B5B' }} />}
                                        title="Quick turnaround"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <HotTubItem
                                        icon={<AutoStoriesIcon sx={{ fontSize: 40, color: '#5B5B5B' }} />}
                                        title="Continuous Research & Development"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <HotTubItem
                                        icon={<SpaIcon sx={{ fontSize: 40, color: '#5B5B5B' }} />}
                                        title="100% Canadian made"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} textAlign="center" sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box component="img" src={PSPHotTub} width="100%" borderRadius={10} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};
export default PSPHotTubSection;
