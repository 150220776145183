import React from 'react';
import { Stack } from '@mui/material';
import { FixNavbarSpace } from '@components/Utilities/Navbar';
import WarrantyRegistrationFormSection from '@components/WarrantyRegistrationForm/WarrantyRegistrationFormSection';
const WarrantyRegistration: React.FunctionComponent = () => {
    return (
        <Stack minHeight="100vh" alignItems="center" spacing={8}>
            <FixNavbarSpace />
            <WarrantyRegistrationFormSection />
        </Stack>
    );
};
export default WarrantyRegistration;
