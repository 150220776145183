import React, { useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Navbar from '@components/Utilities/Navbar';
import CustomDrawer from '@components/Utilities/CustomDrawer';
import Footer from '@components/Utilities/Footer';
import Loader from '@components/Utilities/Loader';
import AlertMessage from '@components/Utilities/AlertMessage';
import { useSelector } from 'react-redux';
import { RootReducer } from '@store/index';

const LandingPage: React.FunctionComponent = () => {
    const { isLoading } = useSelector((state: RootReducer) => state.app);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <Box>
            <CssBaseline />
            <Navbar handleDrawerToggle={handleDrawerToggle} />
            <CustomDrawer drawerOpen={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
            <Outlet />
            <Footer />
            {isLoading && <Loader />}
            <AlertMessage />
        </Box>
    );
};
export default LandingPage;
