import { Button, Container, Divider, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FixNavbarSpace } from '@components/Utilities/Navbar';
import ErrorMessage from '../Utilities/ErrorMessage';
import { getBase64FromInputFile } from '@root/src/shared/utils/Base64';
import axios from 'axios';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { hideLoader, showLoader } from '@utils/Loader';
import { showAlertError } from '@utils/Alerts/Error';
import { showAlertMessage } from '@utils/Alerts/Message';
import { db, collection, getDocs, doc } from '../../../api/Firebase/Firebase';
import { addDoc, getDoc, query, where } from 'firebase/firestore';

const validationSchema = Yup.object({
    clientName: Yup.string()
        .matches(/^[a-zA-Z ]*$/, 'Only letters and spaces are allowed')
        .required('Customer name is required'),
    coverId: Yup.string().required('Cover ID is required'),
    phoneNumber: Yup.string()
        .matches(/^[0-9]*$/, 'Only numbers are allowed')
        .required('Phone number is required'),
    details: Yup.string().required('Details are required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    address: Yup.string().required('Address is required')
});

const WarrantyClaim = () => {
    const coverType = window.localStorage.getItem("coverType");
    const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });
    const preset_key = "wwomre9w";
    const cloud_name = "dvizb7eqo";
    const [image, setImage] = useState();
    const [clientName, setName] = useState('');
    const [coverId, setId] = useState('');
    const [phoneNumber, setPhone] = useState('');
    const [details, setDetails] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    
    interface Item {
        clientName: string; coverId: string; phoneNumber: string; details: string; status: string; email: string; address: string;
    }
    const [items, setItems] = useState<Item[]>([]);

    const onSubmit = async (data: any) => {
        showLoader();
        const serviceId = 'service_20rn1wh';
        const templateId = 'template_ti4lm28';
        const publicKey = 'KEOHcrYRqnhQJOkaZ';

        const templateParams = {
            from_covertype: coverType,
            from_client: clientName,
            from_coverid: coverId,
            from_phone: phoneNumber,
            from_details: details,
            from_email: email,
            from_address: address,
            attachment: image
        };

        try {
            const querySnapshot = await getDocs(query(collection(db, 'claims'), where('coverId', '==', coverId)));

            if (!querySnapshot.empty) {
                const itemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as unknown as Item));
                setItems(itemsData);
                showAlertError('A Warranty Claim has already been registered for this CoverID');
            } else {
                const newItem = {
                    clientName,
                    coverId,
                    phoneNumber,
                    details,
                    email,
                    address,
                    status: "processing"
                };

                await addDoc(collection(db, 'claims'), newItem);
                setItems([newItem as Item]);

                try {
                    const res = await axios.post("https://api.emailjs.com/api/v1.0/email/send", {
                        service_id: serviceId,
                        template_id: templateId,
                        user_id: publicKey,
                        template_params: templateParams
                    });

                    console.log(res.data);

                    if (res) {
                        showAlertMessage('Warranty Claim sent successfully');
                    }
                } catch (error) {
                    showAlertError('Error sending Warranty Claim, please try later');
                }
            }
        } catch (error) {
            showAlertError('Error sending Warranty Claim, please try later');
        }

        reset();
        setName('');
        setId('');
        setPhone('');
        setDetails('');
        setEmail('');
        setAddress('');
        setImage(undefined);
        hideLoader();
    };

    const addFile = async (event: any) => {
        showLoader();
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append("upload_preset", preset_key);
        axios.post(`https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`, formData)
            .then(res => setImage(res.data.secure_url))
            .catch(err => console.log(err));
        hideLoader();
    };

    return (
        <Stack minHeight="60vh" alignItems="center" spacing={15}>
            <FixNavbarSpace />

            <form onSubmit={handleSubmit(onSubmit)} id='warranty-claim-form'>
                <Container>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" fontWeight={600}>
                                {coverType}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="clientName"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label="Customer name"
                                                variant="standard"
                                                value={field.value || ''}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setName(e.target.value);
                                                }}
                                                error={!!errors.clientName}
                                                helperText={errors.clientName ? String(errors.clientName.message) : ''}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="coverId"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label="Cover ID"
                                                variant="standard"
                                                value={field.value || ''}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setId(e.target.value);
                                                }}
                                                error={!!errors.coverId}
                                                helperText={errors.coverId ? String(errors.coverId.message) : ''}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="phoneNumber"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label="Phone number"
                                                variant="standard"
                                                value={field.value || ''}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setPhone(e.target.value);
                                                }}
                                                error={!!errors.phoneNumber}
                                                helperText={errors.phoneNumber ? String(errors.phoneNumber.message) : ''}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="details"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label="Describe the issue"
                                                variant="standard"
                                                value={field.value || ''}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setDetails(e.target.value);
                                                }}
                                                error={!!errors.details}
                                                helperText={errors.details ? String(errors.details.message) : ''}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label="Email"
                                                variant="standard"
                                                value={field.value || ''}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setEmail(e.target.value);
                                                }}
                                                error={!!errors.email}
                                                helperText={errors.email ? String(errors.email.message) : ''}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="address"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label="Address"
                                                variant="standard"
                                                value={field.value || ''}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setAddress(e.target.value);
                                                }}
                                                error={!!errors.address}
                                                helperText={errors.address ? String(errors.address.message) : ''}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <Button variant="text" component="label" color="inherit">
                                        CLICK HERE TO LOAD A PICTURE OF THE ISSUE
                                        <input type="file" name="image" hidden onChange={addFile} />
                                    </Button>
                                    <br></br>
                                    <img src={image} style={{
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        display: 'block',
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                        maxWidth: '80%'
                                    }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </Stack>
    );
};

export default WarrantyClaim;
