import * as Yup from 'yup';
import React, { useState } from 'react';
import {
    Grid,
    Typography,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputLabel,
    Button
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ProductSelect from '@components/Utilities/Selects/ProductSelect';
import ErrorMessage from '@components/Utilities/ErrorMessage';
import { getBase64FromInputFile } from '@utils/Base64';
export const orderInformationSchema = Yup.object({
    orderInformation: Yup.object().shape({
        dealerName: Yup.string().required('Dealer name is required'),
        product: Yup.string().required('Product is required'),
        date: Yup.date().required('Date is required'),
        orderNumber: Yup.string().required('Order number is required'),
        installed: Yup.string().required('Installed by is required'),
        file: Yup.object().shape({
            fileName: Yup.string().required('File is required'),
            src: Yup.string()
        })
    })
});
interface OrderInformationProps {
    warrantyRegistrationForm: any;
}
const OrderInformation: React.FunctionComponent<OrderInformationProps> = (props) => {
    const { warrantyRegistrationForm } = props;
    const {
        control,
        setValue,
        formState: { errors }
    } = warrantyRegistrationForm;
    const [fileName, setFileName] = useState<string>('');

    const addFile = async (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const base64File = await getBase64FromInputFile(file);
            setValue('orderInformation.file.fileName', file.name);
            setValue('orderInformation.file.src', base64File);
            setFileName(file.name);
        }
    };

    return (
        <Grid container>
            <Grid item xs={12} md={4}>
                <Typography variant="h6" fontWeight={600}>
                    Order Information
                </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="orderInformation.dealerName"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    fullWidth
                                    required
                                    label="Name of the dealer"
                                    variant="standard"
                                    value={value}
                                    onChange={onChange}
                                    error={!!errors.orderInformation?.dealerName}
                                    helperText={
                                        errors.orderInformation?.dealerName &&
                                        errors.orderInformation?.dealerName.message
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="orderInformation.product"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormControl fullWidth required error={!!errors.orderInformation?.product}>
                                    <InputLabel id="product-label">Product purchased</InputLabel>
                                    <ProductSelect
                                        label="Product purchased"
                                        labelId="product-label"
                                        variant="standard"
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors.orderInformation?.product}
                                    />
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="orderInformation.date"
                            defaultValue={new Date()}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Date Product Reached"
                                        format="dd-MM-yyyy"
                                        value={value}
                                        onChange={onChange}
                                        slots={{
                                            textField: (props) => (
                                                <TextField
                                                    {...props}
                                                    variant="standard"
                                                    error={!!errors.orderInformation?.date}
                                                    helperText={
                                                        errors.orderInformation?.date &&
                                                        errors.orderInformation?.date.message
                                                    }
                                                />
                                            )
                                        }}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={4}>
                        <Controller
                            name="orderInformation.cover.ID"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    fullWidth
                                    required
                                    label="Cover ID"
                                    variant="standard"
                                    value={value}
                                    onChange={onChange}
                                    error={!!errors.orderInformation?.orderNumber}
                                    helperText={
                                        errors.orderInformation?.orderNumber &&
                                        errors.orderInformation?.orderNumber.message
                                    }
                                />
                            )}
                        />
                    </Grid> */}
                  
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="orderInformation.orderNumber"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    fullWidth
                                    required
                                    label="Sales Order Number"
                                    variant="standard"
                                    value={value}
                                    onChange={onChange}
                                    error={!!errors.orderInformation?.orderNumber}
                                    helperText={
                                        errors.orderInformation?.orderNumber &&
                                        errors.orderInformation?.orderNumber.message
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button variant="text" component="label" color="inherit">
                            LOAD ORDER RECEIPT
                            <input type="file" hidden onChange={addFile} />
                        </Button>
                        <Typography variant="subtitle2" mt={2}>
                            {fileName}
                        </Typography>
                        {errors.orderInformation?.file.fileName && (
                            <ErrorMessage>{errors.orderInformation?.file.fileName.message}</ErrorMessage>
                        )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="orderInformation.installed"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormControl required error={!!errors.orderInformation?.installed}>
                                    <FormLabel id="installed-group-label">Installed by</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="installed-group-label"
                                        value={value}
                                        onChange={onChange}
                                        name="orderInformation.installed-group"
                                    >
                                        <FormControlLabel value="Dealer" control={<Radio />} label="Dealer" />
                                        <FormControlLabel value="Private" control={<Radio />} label="Private" />
                                        <FormControlLabel value="PSP" control={<Radio />} label="PSP" />
                                    </RadioGroup>
                                </FormControl>
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default OrderInformation;
