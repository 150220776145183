import React, { ComponentType } from 'react';
import { Avatar, IconProps } from '@mui/material';

interface CircleIconProps {
    circleSize?: number;
    circleColor?: string;
    Icon: ComponentType<any>;
    iconSize?: number;
    iconColor?: string;
}
const CircleIcon: React.FunctionComponent<CircleIconProps> = (props) => {
    const { circleSize = 80, circleColor = 'white', Icon, iconSize = 60, iconColor = '#0086CA' } = props;
    return (
        <Avatar sx={{ width: circleSize, height: circleSize, backgroundColor: circleColor, boxShadow: 8 }}>
            <Icon style={{ fontSize: iconSize, color: iconColor }} />
        </Avatar>
    );
};

export default CircleIcon;
