import React from 'react';
import { Stack } from '@mui/material';
import { FixNavbarSpace } from '@components/Utilities/Navbar';
import AvailableColors from '@components/SafetyCover/TechSpecs/AvailableColors';
import BasicShapesSection from '@components/SafetyCover/TechSpecs/BasicShapes';
import InstallationSteps from '@components/SafetyCover/TechSpecs/InstallationSteps';
import MeasuringSection from '@components/SafetyCover/TechSpecs/Measuring';
import StockSection from '@components/SafetyCover/TechSpecs/Stock';
import ReplacementSection from '@components/SafetyCover/TechSpecs/ReplacementSection';
import WarrantySection from '@components/SafetyCover/TechSpecs/Warranty';

const SafetyCoverTechSpecs: React.FunctionComponent = () => {
    return (
        <Stack minHeight="100vh" alignItems="center" spacing={8}>
            <FixNavbarSpace />
            <AvailableColors />
            <BasicShapesSection />
            <InstallationSteps />
            <MeasuringSection />
            <StockSection />
            <ReplacementSection />
            <WarrantySection />
        </Stack>
    );
};
export default SafetyCoverTechSpecs;
