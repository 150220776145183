import React from 'react';
import { Container, Box, Grid, Typography, Stack, Divider } from '@mui/material';
import measuring1 from '@assets/safetyCover/techSpecs/measuring/measuring1.png';
import measuring2 from '@assets/safetyCover/techSpecs/measuring/measuring2.jpg';
const MeasuringSection: React.FunctionComponent = () => {
    return (
        <Container>
            <Grid container>
                <Grid item xs={12} mb={4}>
                    <Divider style={{ width: '100%', background: 'lightgrey', borderBottomWidth: 3 }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4">A - B Measuring</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Stack spacing={2}>
                        <Typography variant="body1">
                            1. Draw a sketch of the pool shape on the grid provided in the measuring sheet.
                        </Typography>
                        <Typography variant="body1">
                            2. To represent the A – B line, mark 2 points on the deck about 3 feet away from the pool.
                            Note that your A is always on the left of B as you face the pool and the pool is in front of
                            your A – B line. This A – B distance should be about 2/3 rd’s of the total length of the
                            pool. Make sure that the A – B line does not cross the pool.
                        </Typography>
                        <Typography variant="body1">
                            3. Measure the A – B length and enter it in the A – B space provided on the sheet.
                        </Typography>
                        <Typography variant="body1">
                            4. Use chalk to mark points clockwise around the entire edge (inside) of the pool at about 3
                            feet intervals. The first point should be directly across from the center of the A – B line.
                            All the corners including the step should be marked and the inside curves should be marked
                            at 1 foot intervals.
                        </Typography>
                        <Typography variant="body1">
                            5. Any obstructions, like slides, legs, ladders, rock waterfalls (all non removable
                            obstructions) within 18 inches away from the water edge should be marked. This could be done
                            last.
                        </Typography>
                        <Typography variant="body1">
                            6. Using a measuring tape, measure the distance from point A on the A – B line to all the
                            points on the pool starting from #1 and record it in the A column of the A – B sheet.
                            Likewise, measure the distance from B to all points on the pool to record it.
                        </Typography>

                        <Typography variant="body1">
                            Note: Measure all radius corners. Make sure that all the radii are equal. A slight
                            difference in each radius will hang the spa or will not cover the spa completely.
                        </Typography>
                        <Box component="img" src={measuring1} width="100%" />
                        <Box component="img" src={measuring2} width="100%" />
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
};
export default MeasuringSection;
