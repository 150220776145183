import { axiosPrivate } from '@api/ApiHelper/axiosPrivate';
import { WarrantyRegistration } from '@api/WarrantyRegistrations/WarrantyRegistration';
import { getBase64, getBase64Extension, getBase64MimeType } from '@utils/Base64';
import StorageService from '@api/StorageService/StorageService';

const FILES_PATH = 'warranty/files/';
class WarrantyRegistrationService {
    addWarrantyRegistration = (warrantyRegistration: WarrantyRegistration): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                const base64 = warrantyRegistration.orderInformation.file.src;
                const fileName = warrantyRegistration.orderInformation.file.fileName;
                const path = `${FILES_PATH}${fileName}.${getBase64Extension(base64)}`;
                warrantyRegistration.orderInformation.file.src = await StorageService.uploadFileToStorage(
                    path,
                    getBase64(base64),
                    getBase64MimeType(base64)
                );
                const data = {
                    contactInformation: warrantyRegistration.contactInformation,
                    orderInformation: {
                        ...warrantyRegistration.orderInformation,
                        date: warrantyRegistration.orderInformation.date.toISOString()
                    },
                    customerService: warrantyRegistration.customerService
                };
                await axiosPrivate.post('/warrantyRegistration/add', { data: warrantyRegistration });
                resolve();
            } catch (error: any) {
                console.log(error);
                reject(error);
            }
        });
    };
}

export default WarrantyRegistrationService;
