import appReducer, { AppState } from '@store/appStore';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

export interface RootReducer {
    app: AppState;
}

const rootReducer = combineReducers({
    app: appReducer
});

const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk]
});
export default store;
