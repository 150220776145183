import { MenuItem, Select, SelectProps, Typography } from '@mui/material';
import React from 'react';

const KnowAbout: React.FunctionComponent<SelectProps> = (props) => {
    return (
        <Select
            variant="standard"
            defaultValue="Dealer"
            renderValue={(selected: any) => <Typography>{selected}</Typography>}
            {...props}
        >
            <MenuItem value="Dealer / Pool service recommendation">Dealer / Pool service recommendation</MenuItem>
            <MenuItem value="Advertisement">Advertisement (specify)</MenuItem>
            <MenuItem value="Through friend/neighbor/relative">Through friend/neighbor/relative</MenuItem>
            <MenuItem value="Other sources">Other sources</MenuItem>
        </Select>
    );
};

export default KnowAbout;
