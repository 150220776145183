import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from '@layouts/LandingPage';
import appRoutes from '@navigation/routes';
import ScrollToTop from '@components/Utilities/ScrollToTop';
import { ThemeProvider } from '@mui/material/styles';
import theme from '@utils/Theme';
import { Provider } from 'react-redux';
import store from '@store/index';
function App() {
    return (
        <Provider store={store}>
            <Router>
                <ThemeProvider theme={theme}>
                    <ScrollToTop />
                    <Routes>
                        <Route path="/" element={<LandingPage />}>
                            {appRoutes.map((prop, key) => {
                                return <Route path={prop.path} element={<prop.element />} key={key} />;
                            })}
                        </Route>
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </ThemeProvider>
            </Router>
        </Provider>
    );
}

export default App;
