import * as Yup from 'yup';
import React from 'react';
import {
    Grid,
    Typography,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputLabel
} from '@mui/material';
import { Controller } from 'react-hook-form';
import KnowAboutSelect from '@components/Utilities/Selects/KnowAboutSelect';

export const customerServiceSchema = Yup.object({
    customerService: Yup.object().shape({
        know: Yup.string().required('Field is required'),
        other: Yup.string(),
        satisfied: Yup.string().required('Field is required'),
        comments: Yup.string()
    })
});
interface CustomerServiceProps {
    warrantyRegistrationForm: any;
}
const CustomerService: React.FunctionComponent<CustomerServiceProps> = (props) => {
    const { warrantyRegistrationForm } = props;
    const {
        control,
        formState: { errors }
    } = warrantyRegistrationForm;
    return (
        <Grid container>
            <Grid item xs={12} md={4}>
                <Typography variant="h6" fontWeight={600}>
                    Customer Service
                </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="customerService.know"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormControl fullWidth required error={!!errors.customerService?.dealer}>
                                    <InputLabel id="know-label">How did you know about PSP?</InputLabel>
                                    <KnowAboutSelect
                                        label="How did you know about PSP?"
                                        labelId="know-label"
                                        variant="standard"
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors.customerService?.know}
                                    />
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="customerService.other"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    fullWidth
                                    label="Other sources"
                                    variant="standard"
                                    value={value}
                                    onChange={onChange}
                                    error={!!errors.customerService?.other}
                                    helperText={errors.customerService?.other && errors.customerService?.other.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="customerService.satisfied"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormControl required error={!!errors.customerService?.satisfied}>
                                    <FormLabel id="satisfied-group-label">
                                        Are you satisfied with your support?
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="satisfied-group-label"
                                        value={value}
                                        onChange={onChange}
                                        name="customerService.satisfied-group"
                                    >
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="customerService.comments"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={5}
                                    label="Comments (optional)"
                                    variant="standard"
                                    value={value}
                                    onChange={onChange}
                                    error={!!errors.customerService?.comments}
                                    helperText={
                                        errors.customerService?.comments && errors.customerService?.comments.message
                                    }
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default CustomerService;
