import { axiosPrivate } from '@api/ApiHelper/axiosPrivate';
import { Contact } from '@api/Contacts/Contact';
class ContactService {
    sentMessage = (contact: Contact): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                await axiosPrivate.post('/contact/sentMessage', { data: contact });
                resolve();
            } catch (error: any) {
                console.log(error);
                reject(error);
            }
        });
    };
}

export default ContactService;
