import React from 'react';
import { Container, Box, Grid, Stack, Typography, Divider } from '@mui/material';
import shape1 from '@assets/spaCover/techSpecs/shapes/shape1.png';
import shape2 from '@assets/spaCover/techSpecs/shapes/shape2.png';
import shape3 from '@assets/spaCover/techSpecs/shapes/shape3.png';
import shape4 from '@assets/spaCover/techSpecs/shapes/shape4.png';
import shape5 from '@assets/spaCover/techSpecs/shapes/shape5.png';
import shape6 from '@assets/spaCover/techSpecs/shapes/shape6.png';
import shape7 from '@assets/spaCover/techSpecs/shapes/shape7.png';
const BasicShapesSection: React.FunctionComponent = () => {
    return (
        <Container>
            <Grid container>
                <Grid item xs={12} mb={4}>
                    <Divider style={{ width: '100%', background: 'lightgrey', borderBottomWidth: 3 }} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4">Basic Shapes</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Please provide the following measurements. Be accurate and clear when ordering.
                            </Typography>
                        </Grid>
                        <Grid item md={5} textAlign="center">
                            <Box component="img" src={shape1} width="100%" />
                            <Typography variant="subtitle1">Squares / Rectangle</Typography>
                        </Grid>
                        <Grid item md={5} textAlign="center">
                            <Box component="img" src={shape2} width="100%" />
                            <Typography variant="subtitle1">Squares / Rectangle with radius corner</Typography>
                        </Grid>
                        <Grid item md={5} textAlign="center">
                            <Box component="img" src={shape3} width="100%" />
                            <Typography variant="subtitle1">Round</Typography>
                        </Grid>
                        <Grid item md={5} textAlign="center">
                            <Box component="img" src={shape4} width="100%" />
                            <Typography variant="subtitle1">Octagon</Typography>
                        </Grid>
                        <Grid item md={5} textAlign="center">
                            <Box component="img" src={shape5} width="100%" />
                            <Typography variant="subtitle1">Stretched Octagon (Four Cut Corners)</Typography>
                        </Grid>
                        <Grid item md={5} textAlign="center">
                            <Box component="img" src={shape6} width="100%" />
                            <Typography variant="subtitle1">One cut corner</Typography>
                        </Grid>
                        <Grid item md={5} textAlign="center">
                            <Box component="img" src={shape7} width="100%" />
                            <Typography variant="subtitle1">Two Cut Corners</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Each spa cover is custom made to your specifications. We will be happy to assist you in
                                determining your cover size and style.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};
export default BasicShapesSection;
