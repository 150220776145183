import React, { useState } from 'react';
import { Box, Stack, Typography, Button, Grid } from '@mui/material';
import { NavLink } from 'react-router-dom';
import spaCover from '@assets/home/spaCover.png';
import safetyCover from '@assets/home/safetyCover.jpeg';
const CoversSection: React.FunctionComponent = () => {
    const [hoverSpa, setHoverSpa] = useState<boolean>(false);
    const [hoverSafety, setHoverSafety] = useState<boolean>(false);

    return (
        <Box width="100%" bgcolor="white">
            <Grid container>
                <Grid height="60vh" item xs={12} md={6}>
                    <Box display="flex" height="100%" sx={{ border: { md: '6px solid white' } }}>
                        <Box
                            component={NavLink}
                            to="/spaCoverOverview"
                            onMouseEnter={() => setHoverSpa(true)}
                            onMouseLeave={() => setHoverSpa(false)}
                            sx={{
                                backgroundImage: `url(${spaCover})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                position: 'absolute',
                                height: 'calc(60vh - 12px)',
                                width: { xs: '100%', md: 'calc(50% - 6px)' },
                                filter: `brightness(${hoverSpa ? '60' : '30'}%)`,
                                cursor: 'pointer'
                            }}
                        />
                        <Stack
                            spacing={2}
                            p={4}
                            justifyContent="center"
                            width="100%"
                            component={NavLink}
                            to="/spaCoverOverview"
                            sx={{
                                textDecoration: 'none',
                                cursor: 'pointer'
                            }}
                            onMouseEnter={() => setHoverSpa(true)}
                            onMouseLeave={() => setHoverSpa(false)}
                        >
                            <Typography variant="h3" color="white" textAlign="center" zIndex={1}>
                                Spa Covers
                            </Typography>
                            <Typography variant="h5" color="white" textAlign="center" zIndex={1}>
                                Perfect for any custom or standard spa and available in 6 colors
                            </Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid height="60vh" item xs={12} md={6}>
                    <Box display="flex" height="100%" sx={{ border: { md: '6px solid white' } }}>
                        <Box
                            component={NavLink}
                            to="/safetyCoverOverview"
                            onMouseEnter={() => setHoverSafety(true)}
                            onMouseLeave={() => setHoverSafety(false)}
                            sx={{
                                backgroundImage: `url(${safetyCover})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                position: 'absolute',
                                height: 'calc(60vh - 12px)',
                                width: { xs: '100%', md: 'calc(50% - 12px)' },
                                filter: `brightness(${hoverSafety ? '60' : '30'}%)`,
                                cursor: 'pointer'
                            }}
                        />
                        <Stack
                            spacing={2}
                            p={4}
                            justifyContent="center"
                            width="100%"
                            component={NavLink}
                            to="/safetyCoverOverview"
                            sx={{
                                textDecoration: 'none',
                                cursor: 'pointer'
                            }}
                            onMouseEnter={() => setHoverSafety(true)}
                            onMouseLeave={() => setHoverSafety(false)}
                        >
                            <Typography variant="h3" color="white" textAlign="center" zIndex={1}>
                                Safety Covers
                            </Typography>
                            <Typography variant="h5" color="white" textAlign="center" zIndex={1}>
                                Custom built to perfectly fit your pool and available in 5 colors
                            </Typography>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
export default CoversSection;
