import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat'
    },
    palette: {
        secondary: {
            main: '#242128'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 30,
                    fontWeight: 600
                },
                contained: {
                    background: 'linear-gradient(to right, #4892CD, #293674)',
                    '&:hover': {
                        background: 'linear-gradient(to right, #75ADD9, #5666B1)'
                    }
                }
            }
        }
    }
});

export default theme;
