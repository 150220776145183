import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

interface FeatureItemProps {
    img: string;
    title: string;
    description: string;
}
const FeatureItem: React.FunctionComponent<FeatureItemProps> = (props) => {
    const { img, title, description } = props;
    return (
        <Stack spacing={2}>
            <Box component="img" src={img} width={40} />
            <Typography variant="h5">{title}</Typography>
            <Typography variant="body1" color="#464646">
                {description}
            </Typography>
        </Stack>
    );
};
export default FeatureItem;
