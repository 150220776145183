import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

const ErrorMessage: React.FunctionComponent<TypographyProps> = (props) => {
    return (
        <Typography variant="subtitle2" color="error" mt={2} {...props}>
            {props.children}
        </Typography>
    );
};

export default ErrorMessage;
